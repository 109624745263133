import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IconModule } from '@lum-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MultiSelectComponent } from './multi-select.component';
import { SelectOptionComponent } from './select-option/select-option.component';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    MatCheckboxModule,
    FormsModule,
    TranslateModule,
  ],
  declarations: [MultiSelectComponent, SelectOptionComponent],
  exports: [MultiSelectComponent],
})
export class MultiSelectModule {}
