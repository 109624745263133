import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { LumBusinessCase, LumBusinessCaseStateHistoryEntry, LumBusinessCaseSummaryBuildup, LumBusinessCaseSummaryBuildupEntry, LumBusinessCaseSummaryProcessing, LumBusinessCaseSummaryProduct, LumBusinessRelationRole, LumDataSource, LumDataView, LumStatisticsResponse } from '@lum-types';
import { StorageUtils } from '@lum-utils';
import { Observable } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService, LumRequestParams } from '../api.service';
import { BaseDataService, LumBaseDataParams } from '../base-data/base-data.abstract';
import { BusinessCaseAPIService } from './business-case.api.service';
import { BusinessCaseMockService } from './business-case.mock.service';

export abstract class BusinessCaseService extends BaseDataService<LumBusinessCase> {
  public abstract getBusinessCaseSummaryBuildup(id: number, params?: LumBaseDataParams<LumBusinessCaseSummaryBuildup>): Observable<LumDataView<LumBusinessCaseSummaryBuildup>>;
  public abstract getBusinessCaseSummaryBuildupEntry(businessCaseId: number, buildup: LumBusinessCaseSummaryBuildup, businessRelationRole: LumBusinessRelationRole): Observable<LumBusinessCaseSummaryBuildupEntry | null>;
  public abstract getBusinessCaseSummaryBuildupEntryById(businessCaseId: number, buildupId: number): Observable<LumBusinessCaseSummaryBuildupEntry | null>;
  public abstract getBusinessCaseSummaryProduct(id: number, params?: LumBaseDataParams<LumBusinessCaseSummaryProduct>): Observable<LumDataView<LumBusinessCaseSummaryProduct>>;
  public abstract getBusinessCaseSummaryProcessing(id: number, params?: LumBaseDataParams<LumBusinessCaseSummaryProcessing>): Observable<LumDataView<LumBusinessCaseSummaryProcessing>>;
  public abstract getBusinessCaseStateHistory(id: number): Observable<LumBusinessCaseStateHistoryEntry[]>;
  public abstract getBusinessCaseStatisticsCount(params: LumRequestParams): Observable<LumStatisticsResponse>;
  public abstract getBusinessCaseStatisticsTimeSaved(params: LumRequestParams): Observable<LumStatisticsResponse>;
  public abstract getBusinessCaseTaskCount(): Observable<LumStatisticsResponse>;
}

export function BusinessCaseFactory(apiService: ApiService, dcuplService: DcuplService): BusinessCaseService {
  if (StorageUtils.get<LumDataSource>('lum.data-source') === 'mock-data') {
    return new BusinessCaseMockService();
  }

  return new BusinessCaseAPIService(apiService, dcuplService);
}

export const LUM_BUSINESS_CASE_PROVIDER: FactoryProvider = {
  provide: BusinessCaseService,
  useFactory: BusinessCaseFactory,
  deps: [ApiService, DcuplService, HttpClient],
};
