import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '@lum-ui/icon';
import { NotificationComponent } from './notification.component';

@NgModule({
  declarations: [NotificationComponent],
  imports: [CommonModule, IconModule],
  exports: [NotificationComponent],
})
export class NotificationModule {}
