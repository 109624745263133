<header class="flex justify-end p-4">
  <!-- <button
    lum-button
    *ngIf="(isAuthenticated$ | async) === false"
    data-cy="header_btn-login"
    routerLink="/login"
  >
    {{ 'lum.header.login' | translate }}
  </button> -->
  <button
    lum-button
    *ngIf="isAuthenticated$ | async"
    (click)="logout()"
    data-cy="header_btn-logout"
  >
    {{ 'lum.header.logout' | translate }}
  </button>
</header>
