import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  LumFormFieldExtComponent,
  LumFormFieldTextType,
  LumInputValue,
  getFloatAsFormattedString,
} from '@lum-form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lum-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent
  extends LumFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() isDisabled = false;
  @Input() placeholder = '';
  @Input() autocomplete = '';
  @Input() type: LumFormFieldTextType = 'text';

  public value?: LumInputValue;
  public outputType: LumFormFieldTextType = 'text';

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.type === 'number') {
      this.outputType = 'text';
    } else {
      this.outputType = this.type ?? 'text';
    }

    this.listenForFormControlChanges();
    this.listenForStatusChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForFormControlChanges(): void {
    this.subscriptions.add(
      this.control?.valueChanges.subscribe((value) => {
        this.setValue(value);
        this.cdRef.detectChanges();
      })
    );

    this.setValue(this.control?.value);
  }

  private setValue(inputValue: LumInputValue): void {
    if (this.type === 'number' && typeof inputValue === 'number') {
      this.value = getFloatAsFormattedString(inputValue);
    } else {
      this.value = inputValue;
    }
  }

  private listenForStatusChanges(): void {
    this.subscriptions.add(
      this.control?.statusChanges.subscribe(() => {
        this.cdRef.detectChanges();
      })
    );
  }

  public onChange(value: LumInputValue): void {
    let newValue: LumInputValue = value ?? '';

    if (this.type === 'number' && newValue !== '-') {
      newValue =
        typeof newValue === 'string' ? newValue.replace(/,/g, '.') : newValue;
      newValue = parseFloat(newValue.toString());
      if (isNaN(newValue)) {
        newValue = '';
      }
    }

    if (this.type === 'date') {
      if (newValue !== '') {
        newValue = new Date(newValue);
        if (isNaN(newValue.getTime())) {
          newValue = undefined;
        }
      } else {
        newValue = undefined;
      }
    }

    this.control?.patchValue(newValue);
    this.control?.markAsTouched();
    this.control?.markAsDirty();
  }

  public onBlur(): void {
    this.control?.markAsTouched();
  }

  public getClasses(): string[] {
    const classes = [];
    if (this.shouldShowErrors()) {
      classes.push('form-error');
    }

    if (this.type === 'search') {
      classes.push('pl-8 pr-6');
    }
    return classes;
  }

  public getValue(): LumInputValue | undefined {
    if (this.type === 'date' && this.value) {
      // convert date to format y-MM-dd
      const date = new Date(this.value);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}-${month < 10 ? '0' + month : month}-${
        day < 10 ? '0' + day : day
      }`;
    }

    return this.value;
  }

  public clearSearch(): void {
    this.control?.setValue('');
  }
}
