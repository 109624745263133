/* eslint-disable sonarjs/no-duplicate-string */
import { neverError } from '@lum-helpers';
import {
  LumAccordionContent,
  LumAssignmentCategory,
  LumAssignmentType,
  LumDcuplModelKey,
  LumProcessingParameterName,
  LumProductCategorySlug,
  LumProductDescriptionDefinition,
  LumUnit,
} from '@lum-types';

export function convertAssignmentCategoryToAssignmentModelName(
  assignmentCategory: LumAssignmentCategory
): string {
  switch (assignmentCategory) {
    case 'GLASS':
      return 'assignment-glass';
    case 'SPACER':
      return 'assignment-spacer';
    case 'INTERLAYER':
      return 'assignment-interlayer';
    case 'SEALING':
      return 'assignment-sealing';
    case 'GEORGIAN_BAR':
      return 'assignment-georgian-bar';
    case 'GAS':
      return 'assignment-gas';
    case 'CAST_RESIN':
      return 'assignment-cast-resin';
    case 'PROCESSING':
      return 'assignment-processing';
    default:
      throw neverError(assignmentCategory);
  }
}

export function convertAssignmentCategoryToModelKey(
  assignmentCategory: LumAssignmentCategory
): LumDcuplModelKey {
  switch (assignmentCategory) {
    case 'GLASS':
      return 'glass';
    case 'SPACER':
      return 'spacer';
    case 'INTERLAYER':
      return 'interlayer';
    case 'SEALING':
      return 'sealing';
    case 'GEORGIAN_BAR':
      return 'georgian-bar';
    case 'GAS':
      return 'gas';
    case 'CAST_RESIN':
      return 'cast-resin';
    case 'PROCESSING':
      return 'processing';
    default:
      throw neverError(assignmentCategory);
  }
}

export function getAssignmentCategoryFromSlug(
  slug: string
): LumAssignmentCategory | undefined {
  switch (slug) {
    case 'glass':
      return 'GLASS';
    case 'spacer':
      return 'SPACER';
    case 'interlayer':
      return 'INTERLAYER';
    case 'sealing':
      return 'SEALING';
    case 'georgian-bar':
      return 'GEORGIAN_BAR';
    case 'gas':
      return 'GAS';
    case 'cast-resin':
      return 'CAST_RESIN';
    case 'processing':
      return 'PROCESSING';
    default:
      return undefined;
  }
}

export function getLabelByAssignmentCategory(
  assignmentCategory: LumAssignmentCategory
): string {
  switch (assignmentCategory) {
    case 'GLASS':
      return `lum.products.glass.title`;
    case 'SPACER':
      return `lum.products.spacer.title`;
    case 'INTERLAYER':
      return `lum.products.interlayer.title`;
    case 'SEALING':
      return `lum.products.sealing.title`;
    case 'GEORGIAN_BAR':
      return `lum.products.georgian-bar.title`;
    case 'GAS':
      return `lum.products.gas.title`;
    case 'CAST_RESIN':
      return `lum.products.castresin.title`;
    case 'PROCESSING':
      return `lum.products.processing.title`;
    default:
      throw neverError(assignmentCategory);
  }
}

export function getSlugByAssignmentCategory(
  assignmentCategory: LumAssignmentCategory
): LumProductCategorySlug {
  switch (assignmentCategory) {
    case 'GLASS':
      return `glass`;
    case 'SPACER':
      return `spacer`;
    case 'INTERLAYER':
      return `interlayer`;
    case 'SEALING':
      return `sealing`;
    case 'GEORGIAN_BAR':
      return `georgian-bar`;
    case 'GAS':
      return `gas`;
    case 'CAST_RESIN':
      return `cast-resin`;
    case 'PROCESSING':
      return `processing`;
    default:
      throw neverError(assignmentCategory);
  }
}

export function getAssignmentTypeByAssignmentCategory(
  assignmentCategory: LumAssignmentCategory
): LumAssignmentType {
  switch (assignmentCategory) {
    case 'GLASS':
    case 'SPACER':
    case 'INTERLAYER':
    case 'SEALING':
    case 'GEORGIAN_BAR':
    case 'GAS':
    case 'CAST_RESIN':
      return 'products';
    case 'PROCESSING':
      return 'processings';
    default:
      throw neverError(assignmentCategory);
  }
}

export function getGlobalAssignmentDescriptionDefinition(
  assignmentCategory: LumAssignmentCategory
): LumProductDescriptionDefinition[] {
  switch (assignmentCategory) {
    case 'GLASS':
      return [
        {
          key: 'name',
          label: 'lum.products.description-label.name',
        },
        {
          key: 'thickness',
          label: 'lum.products.description-label.thickness',
          unit: 'mm',
        },
        {
          key: 'manufacturer',
          label: 'lum.products.description-label.manufacturer',
        },
        {
          key: 'coating',
          label: 'lum.products.description-label.coating',
        },
        {
          key: 'lamination_buildup',
          label: 'lum.products.description-label.lamination_buildup',
        },
        {
          key: 'color_family',
          label: 'lum.products.description-label.color',
        },
        {
          key: 'ornament',
          label: 'lum.products.description-label.ornament',
        },
      ];
    case 'SPACER':
      return [
        {
          key: 'name',
          label: 'lum.products.description-label.name',
        },
        {
          key: 'width',
          label: 'lum.products.description-label.width',
          unit: 'mm',
        },
        {
          key: 'manufacturer',
          label: 'lum.products.description-label.manufacturer',
        },
        {
          key: 'color_family',
          label: 'lum.products.description-label.color',
        },
        {
          key: 'height',
          label: 'lum.products.description-label.height',
          unit: 'mm',
        },
      ];

    case 'INTERLAYER':
      return [
        {
          key: 'name',
          label: 'lum.products.description-label.name',
        },
        {
          key: 'thickness',
          label: 'lum.products.description-label.thickness',
          unit: 'mm',
        },
        {
          key: 'manufacturer',
          label: 'lum.products.description-label.manufacturer',
        },
        {
          key: 'color_family',
          label: 'lum.products.description-label.color',
        },
      ];

    case 'SEALING':
      return [
        {
          key: 'name',
          label: 'lum.products.description-label.name',
        },
        {
          key: 'manufacturer',
          label: 'lum.products.description-label.manufacturer',
        },
        {
          key: 'color_family',
          label: 'lum.products.description-label.color',
        },
      ];

    case 'GEORGIAN_BAR':
      return [
        {
          key: 'name',
          label: 'lum.products.description-label.name',
        },
        {
          key: 'width',
          label: 'lum.products.description-label.width',
          unit: 'mm',
        },
        {
          key: 'height',
          label: 'lum.products.description-label.height',
          unit: 'mm',
        },
        {
          key: 'manufacturer',
          label: 'lum.products.description-label.manufacturer',
        },
        {
          key: 'color_family',
          label: 'lum.products.description-label.color',
        },
      ];

    case 'GAS':
      return [
        {
          key: 'name',
          label: 'lum.products.description-label.name',
        },
      ];

    case 'CAST_RESIN':
      return [
        {
          key: 'name',
          label: 'lum.products.description-label.name',
        },
        {
          key: 'manufacturer',
          label: 'lum.products.description-label.manufacturer',
        },
      ];

    case 'PROCESSING':
      return [
        {
          key: 'processing',
          label: 'lum.products.description-label.processing',
        },
        {
          key: 'category',
          label: 'lum.products.description-label.processing-category',
        },
      ];

    default:
      throw neverError(assignmentCategory);
  }
}

export function assignmentDetails<T>(
  assignment?: T,
  assignmentCategory?: LumAssignmentCategory
): LumAccordionContent[] {
  if (!assignment || !assignmentCategory) {
    return [];
  }
  const definition =
    getGlobalAssignmentDescriptionDefinition(assignmentCategory);
  return definition.map((def) => ({
    label: def.label,
    value: getAssignmentDetailInfo<T>(def.key, assignment, def.unit),
  }));
}

export function getAssignmentDetailInfo<T>(
  key: unknown,
  assignment: T,
  unit?: LumUnit
): string | number {
  const data = assignment[key as keyof T];
  if (!data) {
    return '-';
  }

  if (typeof data === 'string' || typeof data === 'number') {
    const value = data ?? '-';
    if (unit && data) {
      return `${value} ${unit}`;
    }
    return value;
  }

  const extendedData = data as T & { key: string };
  return extendedData?.key ?? '-';
}

export function getLabelByProcessingParameterName(
  name: LumProcessingParameterName
): string {
  switch (name) {
    case 'WIDTH':
      return 'lum.processings.parameter.width';
    case 'HEIGHT':
      return 'lum.processings.parameter.height';
    case 'DIAMETER':
      return 'lum.processings.parameter.diameter';
    case 'DIAMETER_2':
      return 'lum.processings.parameter.diameter2';
    case 'DIAMETER_3':
      return 'lum.processings.parameter.diameter3';
    case 'RADIUS':
      return 'lum.processings.parameter.radius';
    case 'ANGLE':
      return 'lum.processings.parameter.angle';
    case 'DISTANCE':
      return 'lum.processings.parameter.distance';
    case 'PERCENTAGE':
      return 'lum.processings.parameter.percentage';
    default:
      throw neverError(name);
  }
}
