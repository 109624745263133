import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'lum-lumeso-header',
  templateUrl: './lumeso-header.component.html',
  styleUrls: ['./lumeso-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class LumesoHeaderComponent {
  @Input() marginBottom = 'mb-20';

  constructor() {}
}
