<div class="flex flex-col max-h-screen">
  <div class="flex-grow p-8 overflow-y-auto">
    <div class="flex justify-between items-top">
      <h5 class="pr-8 typo-h5">
        <ng-content select="#header"></ng-content>
      </h5>
      <button
        *ngIf="!disableClose"
        type="button"
        (click)="onCloseClick()"
        data-cy="btn-dialog-close"
      >
        <lum-icon name="x-24" [size]="24"></lum-icon>
      </button>
    </div>

    <div class="mt-5">
      <ng-content></ng-content>
    </div>
  </div>
  <div
    class="px-8 py-4 bg-white border-t border-gray-100"
    *ngIf="isFooterVisible"
  >
    <ng-content select="#footer"></ng-content>
  </div>
</div>
