import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { DcuplList } from '@dcupl/core';
import { LumAssignment, LumAssignmentProcessing, LumClientProcessing, LumDataSource, LumProcessingCount } from '@lum-types';
import { StorageUtils } from '@lum-utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService } from '../api.service';
import { AssignmentService } from '../assignment';
import { BaseDataService } from '../base-data/base-data.abstract';
import { ProcessingAPIService } from './processing.api.service';
import { ProcessingMockService } from './processing.mock.service';

export abstract class ProcessingService extends BaseDataService<LumAssignmentProcessing> implements AssignmentService {
  public abstract dcuplList?: DcuplList<LumAssignment>;
  public abstract processingCount$?: BehaviorSubject<LumProcessingCount | null>;
  public abstract getProcessingCount(): Observable<LumProcessingCount>;
  public abstract createProcessingAssignment(processing: LumClientProcessing): Observable<LumClientProcessing>;
  public abstract updateProcessingAssignment(processing: LumClientProcessing): Observable<LumClientProcessing>;
  public abstract importAssignments(file: File | null): Observable<void>;
}

export function ProcessingFactory(apiService: ApiService, dcuplService: DcuplService): ProcessingService {
  if (StorageUtils.get<LumDataSource>('lum.data-source') === 'mock-data') {
    return new ProcessingMockService();
  }

  return new ProcessingAPIService(apiService, dcuplService);
}

export const LUM_PROCESSING_PROVIDER: FactoryProvider = {
  provide: ProcessingService,
  useFactory: ProcessingFactory,
  deps: [ApiService, DcuplService, HttpClient],
};
