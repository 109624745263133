import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@lum-ui/button';
import { FormFieldModule } from './form-field';

@NgModule({
  declarations: [],
  imports: [CommonModule, FormFieldModule, ButtonModule],
  exports: [FormFieldModule],
})
export class FormModule {}
