import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@lum-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorComponent } from './error.component';

@NgModule({
  declarations: [ErrorComponent],
  imports: [CommonModule, IconModule, TranslateModule],
  exports: [ErrorComponent],
})
export class ErrorModule {}
