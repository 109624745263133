import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@lum-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ToastMessageComponent } from './toast-message.component';

@NgModule({
  imports: [CommonModule, TranslateModule, IconModule],
  declarations: [ToastMessageComponent],
  exports: [ToastMessageComponent],
})
export class ToastMessageModule {}
