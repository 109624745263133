<div class="relative flex items-start">
  <label
    class="flex items-center transition-colors duration-150"
    [ngClass]="getClasses()"
  >
    <input
      type="checkbox"
      class="form-checkbox"
      [disabled]="isReadonly || isDisabled"
      [checked]="isChecked"
      (change)="onChange()"
      [ngClass]="{ 'border-error-600': hasError }"
      [attr.data-cy]="'checkbox-' + dataCy"
    />
    <span
      [innerHTML]="innerLabel"
      class="pl-2 pr-1 font-semibold typo-label-sm"
      [ngClass]="{
        'text-gray-400': isReadonly || isDisabled,
        'text-error-600': hasError,
      }"
    ></span>
  </label>

  <lum-icon
    dataCy="checkbox"
    name="check-16"
    class="absolute text-white top-0.5 left-0.5 pointer-events-none"
    [ngClass]="{
      '!text-gray-300': isChecked && (isReadonly || isDisabled),
    }"
  ></lum-icon>
</div>
