<textarea
  [disabled]="isDisabled"
  [rows]="rows"
  (blur)="onBlur()"
  [placeholder]="placeholder"
  [ngClass]="getClasses()"
  [id]="htmlId"
  [ngModel]="value"
  (ngModelChange)="onChange($event)"
  class="form-textarea"
  [attr.data-cy]="'textarea-' + dataCy"
  #textarea
></textarea>
