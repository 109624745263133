import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  LumFormFieldExtComponent,
  LumFormValue,
  LumSelectOption,
} from '@lum-form';
import { getUniqueId } from '@lum-helpers';
import { MultiSelectService } from '@lum-services';
import { Subscription, distinctUntilChanged, fromEvent } from 'rxjs';

@Component({
  selector: 'lum-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent<OptionKey extends LumFormValue>
  extends LumFormFieldExtComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() isDisabled? = false;
  @Input() placeholder?: string;
  @Input({ required: true }) options!: LumSelectOption<OptionKey>[];

  public isDropdownVisible = false;
  public selectedOptions: LumSelectOption<OptionKey>[] = [];

  @ViewChild('multiselectWrapperRef')
  multiselectWrapperRef?: ElementRef<HTMLDivElement>;

  @ViewChild('multiselectInnerWrapperRef')
  multiselectInnerWrapperRef?: ElementRef<HTMLDivElement>;

  @HostListener('window:resize')
  public onResize(): void {
    this.calculateVisibility();
  }

  public visibleButtons: boolean[] = [];

  private subscriptions = new Subscription();
  private multiSelectId = getUniqueId();

  constructor(
    private cdRef: ChangeDetectorRef,
    private multiSelectService: MultiSelectService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.control?.valueChanges.subscribe(() => {
        this.refreshSelectedOptions();
        this.cdRef.markForCheck();
      })
    );

    this.refreshSelectedOptions();

    this.subscriptions.add(
      this.control?.statusChanges.subscribe(() => {
        this.cdRef.markForCheck();
      })
    );

    this.listenForActiveMultiSelect();
    this.listenForClickOutside();
    this.calculateVisibility();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.refreshSelectedOptions();
      this.cdRef.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForActiveMultiSelect(): void {
    this.subscriptions.add(
      this.multiSelectService.activeMultiSelect$
        .pipe(distinctUntilChanged())
        .subscribe((id) => {
          this.isDropdownVisible = id === this.multiSelectId;
          this.cdRef.detectChanges();
        })
    );
  }

  private listenForClickOutside(): void {
    this.subscriptions.add(
      fromEvent(window, 'click').subscribe((e) => {
        if (this.isDropdownVisible) {
          const event = e as MouseEvent;
          const target = event.target as HTMLElement;

          if (!target.closest('.js-multi-select')) {
            this.multiSelectService.setActiveMultiSelect(null);
          }
        }
      })
    );
  }

  public onOptionClick(option: LumSelectOption<OptionKey>): void {
    const optionAlreadySelected = this.selectedOptions.find(
      (parentOption: LumSelectOption<OptionKey>) =>
        parentOption.key === option.key
    );
    if (!optionAlreadySelected) {
      this.selectOption(option);
    } else {
      this.unselectOption(option);
    }
    this.refreshFormControl();
    this.calculateVisibility();
  }

  private calculateVisibility(): void {
    const wrapperWidth =
      this.multiselectWrapperRef?.nativeElement.clientWidth ?? 0;
    const buttons: HTMLButtonElement[] = [].slice.call(
      this.multiselectInnerWrapperRef?.nativeElement.children
    );
    let width = 0;
    this.visibleButtons = [
      ...buttons.map((button) => {
        width += button.clientWidth;
        width += 4; // add 4px for gap between buttons
        return width < wrapperWidth;
      }),
    ];
    this.cdRef.markForCheck();
  }

  private selectOption(option: LumSelectOption<OptionKey>): void {
    this.selectedOptions.push(option);
  }

  private unselectOption(option: LumSelectOption<OptionKey>): void {
    this.selectedOptions = this.selectedOptions.filter(
      (parentOption: LumSelectOption<OptionKey>) =>
        parentOption.key !== option.key
    );
  }

  private refreshFormControl(): void {
    this.control?.setValue(this.selectedOptions.map((option) => option.key));
  }

  private refreshSelectedOptions(): void {
    this.selectedOptions = this.options.filter((option) =>
      this.control?.value?.includes(option.key)
    );
  }

  public isSelected(option: LumSelectOption<OptionKey>): boolean {
    return this.selectedOptions.some(
      (parentOption: LumSelectOption<OptionKey>) =>
        parentOption.key === option.key
    );
  }

  public trackByOption(
    index: number,
    option: LumSelectOption<OptionKey>
  ): LumFormValue {
    return option.key;
  }

  public onDropdownToggleClick(): void {
    this.multiSelectService.setActiveMultiSelect(
      this.isDropdownVisible ? null : this.multiSelectId
    );
  }

  public getInvisibleButtonsCount(): number {
    return this.visibleButtons.filter((button) => !button).length;
  }
}
