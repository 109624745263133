import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '@lum-services';

@Component({
  selector: 'lum-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  public isAuthenticated$ = this.authService.isAuthenticated$();

  constructor(private readonly authService: AuthService) {}

  ngOnInit(): void {}

  public logout(): void {
    this.authService.logout();
  }
}
