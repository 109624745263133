import { NgModule } from '@angular/core';
import { LumProcessingParametersPipe } from './data-transformer/processing-parameters.pipe';
import { LumFormatPipe } from './format.pipe';
import { LumLocaleDatePipe } from './locale-date.pipe';
import { LumPluralTranslatePipe } from './pluralTranslate.pipe';

const LUM_PIPES = [
  LumLocaleDatePipe,
  LumPluralTranslatePipe,
  LumFormatPipe,
  LumProcessingParametersPipe,
];

@NgModule({
  exports: LUM_PIPES,
  declarations: LUM_PIPES,
  providers: LUM_PIPES,
})
export class LumPipesModule {}
