import { Injectable } from '@angular/core';
import { Projection } from '@dcupl/common';
import {
  LumAlternativeProduct,
  LumBusinessRelation,
  LumBusinessRelationRequest,
  LumBusinessRelationState,
  LumDataView,
  LumStatisticsResponse,
} from '@lum-types';
import { catchError, Observable, of } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService, LumRequestParams } from '../api.service';
import { LumBaseDataParams } from '../base-data/base-data.abstract';
import { BaseDataAPIService } from '../base-data/base-data.api.service';
import { BusinessRelationService } from './business-relation.abstract';

@Injectable({
  providedIn: 'root',
})
export class BusinessRelationAPIService
  extends BaseDataAPIService<LumBusinessRelation>
  implements BusinessRelationService
{
  private alternativeProductsQueryParams: LumRequestParams = {};

  constructor(
    public override apiService: ApiService,
    public override dcuplService: DcuplService
  ) {
    super(apiService, dcuplService);
    this._baseApiRoute = 'business-relation';
  }

  public override getAll(
    params?: LumBaseDataParams<LumBusinessRelation>,
    dcuplModelKey?: string,
    dcuplProjection?: Projection<LumBusinessRelation>,
    skipPathAll?: boolean
  ): Observable<LumDataView<LumBusinessRelation>> {
    this.queryParams = {};
    if (params?.filters) {
      params.filters = params.filters?.filter((f) => !!f.value);
    }
    return super.getAll(params, dcuplModelKey, dcuplProjection, skipPathAll);
  }

  public createAlternativeProduct(
    alternativeProduct: LumAlternativeProduct
  ): Observable<LumAlternativeProduct> {
    return this.apiService.post<LumAlternativeProduct>({
      endpoint: this._baseApiRoute,
      path: '{id}/alternative-product',
      id: alternativeProduct.businessRelationId,
      body: {
        ...alternativeProduct,
      },
    });
  }

  public getAlternativeProducts(
    businessRelationId: number,
    params?: LumBaseDataParams<LumAlternativeProduct>
  ): Observable<LumDataView<LumAlternativeProduct>> {
    this.applyParams<LumAlternativeProduct>(
      this.alternativeProductsQueryParams,
      params
    );

    return this.apiService.get<LumDataView<LumAlternativeProduct>>({
      endpoint: this._baseApiRoute,
      path: '{id}/alternative-product',
      id: businessRelationId,
      queryParams: this.alternativeProductsQueryParams,
    });
  }

  public createBusinessRelation(
    request: LumBusinessRelationRequest
  ): Observable<void> {
    return this.apiService.post<void>({
      endpoint: this._baseApiRoute,
      body: request,
    });
  }

  public updateBusinessRelationState(
    id: number,
    state: LumBusinessRelationState
  ): Observable<void> {
    return this.apiService.put<void>({
      endpoint: this._baseApiRoute,
      path: '{id}/state',
      id,
      body: { state },
    });
  }

  public getBusinessRelationTaskCount(): Observable<LumStatisticsResponse> {
    return this.apiService
      .get<LumStatisticsResponse>({
        endpoint: this._baseApiRoute,
        path: 'task-count',
      })
      .pipe(catchError(() => of({})));
  }
}
