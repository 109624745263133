import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@lum-ui/button';
import { DialogModule } from '@lum-ui/dialog';
import { IconModule } from '@lum-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DialogConfirmComponent } from './dialog-confirm.component';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    TranslateModule,
    IconModule,
  ],
  declarations: [DialogConfirmComponent],
  exports: [DialogConfirmComponent],
})
export class DialogConfirmModule {}
