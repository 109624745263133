import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { LumClientBuildup, LumDataSource } from '@lum-types';
import { StorageUtils } from '@lum-utils';
import { Observable } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService } from '../api.service';
import { BaseDataService } from '../base-data/base-data.abstract';
import { BuildupAPIService } from './buildup.api.service';
import { BuildupMockService } from './buildup.mock.service';

export abstract class BuildupService extends BaseDataService<LumClientBuildup> {
  public abstract importBuildups(file: File | null): Observable<void>;
  public abstract createClientBuildup(buildup: LumClientBuildup): Observable<void>;
}

export function BuildupFactory(apiService: ApiService, dcuplService: DcuplService): BuildupService {
  if (StorageUtils.get<LumDataSource>('lum.data-source') === 'mock-data') {
    return new BuildupMockService();
  }

  return new BuildupAPIService(apiService, dcuplService);
}

export const LUM_BUILDUP_PROVIDER: FactoryProvider = {
  provide: BuildupService,
  useFactory: BuildupFactory,
  deps: [ApiService, DcuplService, HttpClient],
};
