/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  LUM_ENVIRONMENT,
  LumEnvironmentInterface,
} from '../types/environment.type';
import { LumLogger } from '../utils/logger.utils';

@Injectable()
export class ApiAuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(LUM_ENVIRONMENT)
    private readonly environment: LumEnvironmentInterface
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith(this.environment.api.baseUrl)) {
      return from(fetchAuthSession()).pipe(
        switchMap((authSession) => {
          const jwt = authSession.tokens?.accessToken?.toString();
          if (!jwt) {
            LumLogger.error('No JWT token found');
          }
          const with_auth_request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${jwt}`,
            },
          });
          return next.handle(with_auth_request);
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
