import { LUM_ENV } from '@lum-environment';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';

export class CustomMissingTranslationHandler
  implements MissingTranslationHandler
{
  public handle(params: MissingTranslationHandlerParams): string {
    if (LUM_ENV.env === 'local') {
      return '{MISSING: ' + params.key + '}';
    }
    return params.key;
  }
}
