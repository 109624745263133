<div
  class="flex items-center space-x-1 js-error-scroll text-error-600 typo-label-xs"
  *ngIf="errors"
>
  <div class="pt-0.5" *ngIf="isIconVisible">
    <lum-icon name="error-16" [size]="16"></lum-icon>
  </div>
  <div>
    <ng-content></ng-content>
    <div *ngIf="errors">
      <div *ngIf="errors['required']">
        {{ 'lum.error.required' | translate }}
      </div>
      <div *ngIf="errors['requiredTrue']">
        {{ 'lum.error.required' | translate }}
      </div>
      <div *ngIf="errors['maxlength']">
        {{ 'lum.error.maxlength' | translate }}
      </div>
      <div *ngIf="errors['minlength']">
        {{ 'lum.error.minlength' | translate }}
      </div>
      <div *ngIf="errors['pattern']">
        {{ 'lum.error.pattern' | translate }}
      </div>
      <div *ngIf="errors['mindate']">
        {{ 'lum.error.mindate' | translate }}
      </div>
      <div *ngIf="errors['mintime']">
        {{ 'lum.error.mintime' | translate }}
      </div>
      <div *ngIf="errors['maxdate']">
        {{ 'lum.error.maxdate' | translate }}
      </div>
      <div *ngIf="errors['min']">{{ 'lum.error.min' | translate }}</div>
      <div *ngIf="errors['max']">{{ 'lum.error.max' | translate }}</div>
      <div *ngIf="errors['email']">{{ 'lum.error.email' | translate }}</div>
      <div *ngIf="errors['url']">{{ 'lum.error.url' | translate }}</div>
      <div *ngIf="errors['urlHttps']">
        {{ 'lum.error.urlHttps' | translate }}
      </div>
      <div *ngIf="errors['phone']">{{ 'lum.error.phone' | translate }}</div>
      <div *ngIf="errors['custom']">{{ errors['custom'] | translate }}</div>
    </div>
  </div>
</div>
