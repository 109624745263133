import { Injectable } from '@angular/core';
import { LumNotificationSettings } from '@lum-types';
import { Observable } from 'rxjs';
import { DcuplService } from '../../../dcupl.service';
import { ApiService } from '../../api.service';
import { BaseDataAPIService } from '../../base-data/base-data.api.service';
import { NotificationSettingsService } from './notification-settings.abstract';

@Injectable({
  providedIn: 'root',
})
export class NotificationSettingsAPIService
  extends BaseDataAPIService<LumNotificationSettings>
  implements NotificationSettingsService
{
  constructor(
    public override apiService: ApiService,
    public override dcuplService: DcuplService
  ) {
    super(apiService, dcuplService);
    this._baseApiRoute = 'notification/settings';
  }

  public getNotificationSettings(): Observable<LumNotificationSettings> {
    return this.getEntity();
  }

  public updateNotificationSettings(
    notificationSettings: LumNotificationSettings
  ): Observable<void> {
    return this.apiService.put<void>({
      endpoint: this._baseApiRoute,
      body: notificationSettings,
    });
  }
}
