<div
  class="flex items-center justify-start px-2 py-1 gap-2.5 rounded-lg"
  [ngClass]="getClasses()"
>
  <div *ngIf="hasIcon && icon" class="pt-1">
    <lum-icon
      [name]="icon"
      [size]="iconSize"
      [ngClass]="{
        'text-info-600': type === 'info',
        'text-error-600': type === 'error',
      }"
    ></lum-icon>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
</div>
