export function getPercentage(
  firstNumber?: number,
  secondNumber?: number
): number {
  if (firstNumber && firstNumber > 0) {
    if (secondNumber && secondNumber > 0) {
      return ((firstNumber - secondNumber) / secondNumber) * 100;
    } else {
      return 100;
    }
  }
  return 0;
}
