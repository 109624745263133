<div class="relative toast w-80" [ngClass]="this.type">
  <div class="flex items-start gap-2">
    <div
      *ngIf="iconName"
      class="flex items-center justify-center w-8 h-8 text-white rounded-md icon"
    >
      <lum-icon [name]="iconName"></lum-icon>
    </div>
    <div class="flex flex-col">
      <span *ngIf="title" class="mt-1 font-semibold typo-label-md">
        {{ title | translate }}
      </span>
      <p
        *ngIf="message"
        class="mt-1 typo-label-sm"
        [innerHTML]="message | translate"
      ></p>
    </div>
  </div>

  <div class="absolute left-2 right-2 bottom-2">
    <div
      *ngIf="options.progressBar"
      class="w-full h-[3px] bg-gray-300 rounded-full overflow-hidden"
    >
      <div class="toast-progress" [style.width]="width() + '%'"></div>
    </div>
  </div>

  <button
    (click)="onRemoveClick()"
    type="button"
    class="absolute top-3 right-3"
  >
    <lum-icon name="x-16" [size]="16"></lum-icon>
  </button>
</div>
