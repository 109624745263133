import { Component, Input } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

@Component({
  template: '',
})
export abstract class LumFormFieldExtComponent {
  @Input() control?: FormControl;
  @Input() htmlId?: string;
  @Input({ required: true }) dataCy!: string;

  protected isTouched(): boolean {
    return !!this.control?.touched;
  }

  protected getErrors(): ValidationErrors | undefined {
    if (this.control?.errors) {
      return this.control.errors;
    }

    return undefined;
  }

  protected shouldShowErrors(): boolean {
    return this.isTouched() && !!this.getErrors();
  }
}
