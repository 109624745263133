import { Injectable } from '@angular/core';
import { neverError } from '@lum-helpers';
import {
  LumBusinessCase,
  LumBusinessCaseStateHistoryEntry,
  LumBusinessCaseSummaryBuildup,
  LumBusinessCaseSummaryBuildupEntry,
  LumBusinessCaseSummaryProcessing,
  LumBusinessCaseSummaryProduct,
  LumBusinessRelationRole,
  LumDataView,
  LumStatisticsResponse,
} from '@lum-types';
import { catchError, Observable, of } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService, LumRequestParams } from '../api.service';
import { LumBaseDataParams } from '../base-data/base-data.abstract';
import { BaseDataAPIService } from '../base-data/base-data.api.service';
import { BusinessCaseService } from './business-case.abstract';

@Injectable({
  providedIn: 'root',
})
export class BusinessCaseAPIService
  extends BaseDataAPIService<LumBusinessCase>
  implements BusinessCaseService
{
  private summaryBuildupQueryParams: LumRequestParams = {};
  private summaryProductQueryParams: LumRequestParams = {};
  private summaryProcessingQueryParams: LumRequestParams = {};

  constructor(
    public override apiService: ApiService,
    public override dcuplService: DcuplService
  ) {
    super(apiService, dcuplService);
    this._baseApiRoute = 'business-case';
  }

  public getBusinessCaseSummaryBuildup(
    id: number,
    params?: LumBaseDataParams<LumBusinessCaseSummaryBuildup>
  ): Observable<LumDataView<LumBusinessCaseSummaryBuildup>> {
    this.applyParams<LumBusinessCaseSummaryBuildup>(
      this.summaryBuildupQueryParams,
      params
    );

    return this.apiService.get<LumDataView<LumBusinessCaseSummaryProcessing>>({
      endpoint: this._baseApiRoute,
      path: '{id}/summary/buildup',
      id,
      queryParams: this.summaryBuildupQueryParams,
    });
  }

  public getBusinessCaseSummaryBuildupEntry(
    businessCaseId: number,
    buildup: LumBusinessCaseSummaryBuildup,
    businessRelationRole: LumBusinessRelationRole
  ): Observable<LumBusinessCaseSummaryBuildupEntry | null> {
    const queryParams: LumRequestParams = {};

    switch (businessRelationRole) {
      case 'CUSTOMER':
        if (buildup.clientBuildupId) {
          queryParams['customerBuildupId'] = buildup.clientBuildupId;
        }
        break;
      case 'SUPPLIER':
        if (buildup.products?.length) {
          queryParams['platformProducts'] = buildup.products
            .map((product) => product.productId + ':' + product.productCategory)
            .join(',');
        }
        break;
      default:
        neverError(businessRelationRole);
    }

    return this.apiService
      .get<LumBusinessCaseSummaryBuildupEntry>({
        endpoint: this._baseApiRoute,
        path: '{id}/buildup',
        id: businessCaseId,
        queryParams,
      })
      .pipe(catchError(() => of(null)));
  }
  public getBusinessCaseSummaryBuildupEntryById(
    businessCaseId: number,
    buildupId: number
  ): Observable<LumBusinessCaseSummaryBuildupEntry | null> {
    return this.apiService
      .get<LumBusinessCaseSummaryBuildupEntry>({
        endpoint: this._baseApiRoute,
        path: '{id}/buildup/{id2}',
        id: businessCaseId,
        id2: buildupId,
      })
      .pipe(catchError(() => of(null)));
  }

  public getBusinessCaseSummaryProduct(
    id: number,
    params?: LumBaseDataParams<LumBusinessCaseSummaryProduct>
  ): Observable<LumDataView<LumBusinessCaseSummaryProduct>> {
    this.applyParams<LumBusinessCaseSummaryProduct>(
      this.summaryProductQueryParams,
      params
    );

    return this.apiService.get<LumDataView<LumBusinessCaseSummaryProduct>>({
      endpoint: this._baseApiRoute,
      path: '{id}/summary/product',
      id,
      queryParams: this.summaryProductQueryParams,
    });
  }

  public getBusinessCaseSummaryProcessing(
    id: number,
    params?: LumBaseDataParams<LumBusinessCaseSummaryProcessing>
  ): Observable<LumDataView<LumBusinessCaseSummaryProcessing>> {
    this.applyParams<LumBusinessCaseSummaryProcessing>(
      this.summaryProcessingQueryParams,
      params
    );

    return this.apiService.get<LumDataView<LumBusinessCaseSummaryProcessing>>({
      endpoint: this._baseApiRoute,
      path: '{id}/summary/processing',
      id,
      queryParams: this.summaryProcessingQueryParams,
    });
  }

  public getBusinessCaseStateHistory(
    id: number
  ): Observable<LumBusinessCaseStateHistoryEntry[]> {
    return this.apiService
      .get<LumBusinessCaseStateHistoryEntry[]>({
        endpoint: this._baseApiRoute,
        path: '{id}/state-history',
        id,
      })
      .pipe(catchError(() => []));
  }

  public getBusinessCaseStatisticsCount(
    params: LumRequestParams
  ): Observable<LumStatisticsResponse> {
    return this.apiService
      .get<LumStatisticsResponse>({
        endpoint: this._baseApiRoute,
        path: 'statistics/count',
        queryParams: params,
      })
      .pipe(catchError(() => of({})));
  }

  public getBusinessCaseStatisticsTimeSaved(
    params: LumRequestParams
  ): Observable<LumStatisticsResponse> {
    return this.apiService
      .get<LumStatisticsResponse>({
        endpoint: this._baseApiRoute,
        path: 'statistics/time-saved',
        queryParams: params,
      })
      .pipe(catchError(() => of({})));
  }

  public getBusinessCaseTaskCount(): Observable<LumStatisticsResponse> {
    return this.apiService
      .get<LumStatisticsResponse>({
        endpoint: this._baseApiRoute,
        path: 'task-count',
      })
      .pipe(catchError(() => of({})));
  }
}
