import { Injectable } from '@angular/core';
import {
  LumApiPath,
  LumConnector,
  LumConnectorCredentials,
  LumDataView,
} from '@lum-types';
import { Observable, filter, first, map, mergeMap } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService } from '../api.service';
import { LumBaseDataParams } from '../base-data/base-data.abstract';
import { BaseDataAPIService } from '../base-data/base-data.api.service';
import { ConnectorService } from './connector.abstract';

@Injectable({
  providedIn: 'root',
})
export class ConnectorAPIService
  extends BaseDataAPIService<LumConnector>
  implements ConnectorService
{
  constructor(
    public override apiService: ApiService,
    public override dcuplService: DcuplService
  ) {
    super(apiService, dcuplService);
    this._baseApiRoute = 'connector';
  }

  public override getAll(
    params?: LumBaseDataParams<LumConnector>
  ): Observable<LumDataView<LumConnector>> {
    return this.apiService.authService.user$().pipe(
      filter((user) => !!user),
      first(),
      mergeMap((user) => {
        if (user?.role === 'PLATFORM_ADMIN') {
          return super.getAll(params);
        } else {
          return super
            .getEntity()
            .pipe(map((response) => ({ content: [response], pagination: {} })));
        }
      })
    );
  }

  public generateConnectorCredentials(
    connectorId?: number
  ): Observable<LumConnectorCredentials> {
    return this.apiService.authService.user$().pipe(
      filter((user) => !!user),
      first(),
      mergeMap((user) => {
        const isPlatformAdmin = user?.role === 'PLATFORM_ADMIN';

        const endpoint = isPlatformAdmin
          ? 'admin/' + this._baseApiRoute
          : this._baseApiRoute;

        const path: LumApiPath = isPlatformAdmin
          ? '{id}/generate-credentials'
          : 'generate-credentials';

        return this.apiService
          .get<LumConnectorCredentials>({
            endpoint,
            path,
            id: connectorId,
          })
          .pipe(map((response) => ({ ...response, isMasked: false })));
      })
    );
  }

  /**
   * platform admin only
   * @param connectorId
   * @param disabled
   * @returns
   */
  public disableConnector(
    connectorId: number,
    disabled: boolean
  ): Observable<void> {
    return this.apiService.patch<void>({
      endpoint: 'admin/' + this._baseApiRoute,
      path: '{id}/disabled',
      id: connectorId,
      body: { disabled },
    });
  }
}
