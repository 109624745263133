import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@lum-ui/button';
import { IconModule } from '@lum-ui/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '../form/ui/form.module';
import { LumPipesModule } from '../pipes/pipes.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, NavigationComponent],
  imports: [
    CommonModule,
    IconModule,
    RouterModule,
    LumPipesModule,
    TranslateModule,
    FormModule,
    MatTooltipModule,
    ButtonModule,
  ],
  exports: [HeaderComponent, FooterComponent, NavigationComponent],
})
export class LayoutModule {}
