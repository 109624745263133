import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { LumDataSource, LumNotificationSettings } from '@lum-types';
import { StorageUtils } from '@lum-utils';

import { Observable } from 'rxjs';
import { DcuplService } from '../../../dcupl.service';
import { ApiService } from '../../api.service';
import { BaseDataService } from '../../base-data/base-data.abstract';
import { NotificationSettingsAPIService } from './notification-settings.api.service';
import { NotificationSettingsMockService } from './notification-settings.mock.service';

export abstract class NotificationSettingsService extends BaseDataService<LumNotificationSettings> {
  public abstract getNotificationSettings(): Observable<LumNotificationSettings>;
  public abstract updateNotificationSettings(notificationSettings: LumNotificationSettings): Observable<void>;
}

export function NotificationSettingsFactory(apiService: ApiService, dcuplService: DcuplService): NotificationSettingsService {
  if (StorageUtils.get<LumDataSource>('lum.data-source') === 'mock-data') {
    return new NotificationSettingsMockService();
  }

  return new NotificationSettingsAPIService(apiService, dcuplService);
}

export const LUM_NOTIFICATION_SETTINGS_PROVIDER: FactoryProvider = {
  provide: NotificationSettingsService,
  useFactory: NotificationSettingsFactory,
  deps: [ApiService, DcuplService, HttpClient],
};
