<div
  [ngClass]="{ 'flex gap-4 items-center': isFlex, 'space-y-2': !isFlex }"
  [attr.data-cy]="'radio-' + dataCy"
>
  <div *ngFor="let option of options; let i = index; trackBy: trackByOption">
    <lum-radio
      [key]="option.key"
      [label]="option.label"
      [groupId]="groupId"
      [control]="control"
      [isDisabled]="isDisabled"
      [dataCy]="'radio-' + dataCy + '-' + i"
    ></lum-radio>
  </div>
</div>
