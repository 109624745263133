import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LumDialogInput } from '@lum-types';

@Component({
  selector: 'lum-dialog-info',
  templateUrl: './dialog-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogInfoComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LumDialogInput,
    public dialogRef: MatDialogRef<LumDialogInput>
  ) {}

  public onCloseClick(): void {
    this.dialogRef.close();
  }
}
