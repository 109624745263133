<div
  class="flex flex-col items-center justify-between gap-8 p-4 mt-8 text-sm leading-5 text-blue-700 border-t border-gray-300 md:border-none md:gap-2 md:flex-row md:order-1 md:mt-0"
>
  <div class="flex flex-col items-center md:gap-6 md:flex-row">
    <div>
      &copy; {{ environment.buildTimestamp | date: 'yyyy' }}
      {{ 'lum.footer.copyright' | translate }}
    </div>
  </div>

  <div class="flex flex-col items-center gap-8 md:flex-row">
    <nav class="flex flex-col items-center gap-4 md:flex-row">
      <a
        href="#"
        class=""
        *ngFor="let link of footerLinks; trackBy: trackBy; let last = last"
        [ngClass]="{ 'border-r pr-4 border-gray-300  typo-label-sm': !last }"
      >
        {{ 'lum.footer.links.' + link | translate }}
      </a>
    </nav>
  </div>
</div>
