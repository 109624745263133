<div class="flex flex-col justify-between h-screen">
  <div>
    <lum-header></lum-header>
    <div class="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
      <h3 class="typo-h3">{{ 'lum.page-not-found.title' | translate }}</h3>
      <p>{{ 'lum.page-not-found.description' | translate }}</p>
      <a lum-button routerLink="/platform">
        {{ 'lum.main.dashboard.btn.platform' | translate }}
      </a>
    </div>
  </div>
  <lum-footer></lum-footer>
</div>
