import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LumFormFieldExtComponent } from '@lum-form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lum-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent
  extends LumFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() innerLabel?: string;
  @Input() additionalInfo?: string;
  @Input() isDisabled = false;

  public isChecked = false;

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.listenForFormControlChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForFormControlChanges(): void {
    this.isChecked = this.control?.value;
    this.subscriptions.add(
      this.control?.valueChanges.subscribe((value) => {
        this.isChecked = value;
        this.cdRef.markForCheck();
      })
    );
  }

  public onChange(): void {
    this.control?.patchValue(!this.isChecked);
    this.control?.markAsDirty();
    this.control?.markAsTouched();
  }

  public getLabelClasses(): string[] {
    const classes = [];

    if (this.isDisabled) {
      classes.push('text-gray-400');
    }

    return classes;
  }
}
