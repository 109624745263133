import { Injectable } from '@angular/core';
import { LumClientBuildup } from '@lum-types';
import { LumLogger } from '@lum-utils';
import { Observable } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService } from '../api.service';
import { BaseDataAPIService } from '../base-data/base-data.api.service';
import { BuildupService } from './buildup.abstract';

@Injectable({
  providedIn: 'root',
})
export class BuildupAPIService
  extends BaseDataAPIService<LumClientBuildup>
  implements BuildupService
{
  constructor(
    protected override apiService: ApiService,
    protected override dcuplService: DcuplService
  ) {
    super(apiService, dcuplService);
    this._baseApiRoute = 'client-buildup';
  }

  public createClientBuildup(buildup: LumClientBuildup): Observable<void> {
    return this.apiService.post<void>({
      endpoint: this._baseApiRoute,
      body: buildup,
      toastMessage: {
        success: {
          title: 'lum.buildup-assignment.success.title',
          message: 'lum.buildup-assignment.success.message',
        },
        error: {
          title: 'lum.buildup-assignment.error.title',
        },
      },
    });
  }

  public importBuildups(file: File | null): Observable<void> {
    if (!file) {
      LumLogger.error('Missing file: importBuildups()');
      throw new Error('Missing file');
    }

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.apiService.post<void>({
      endpoint: this._baseApiRoute,
      path: 'file-upload',
      body: formData,
      responseType: 'text',
      toastMessage: {
        success: {
          title: 'lum.buildups.import.success.title',
          message: 'lum.buildups.import.success.message',
        },
        error: {
          title: 'lum.buildups.import.error.title',
        },
      },
    });
  }
}
