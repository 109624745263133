/* eslint-disable @typescript-eslint/naming-convention */
import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { StorageUtils } from '../utils/storage.utils';

export const authGuard: CanActivateFn = (route, state) => {
  return LUM_AUTH_GUARD(route, state);
};

export const authGuardChild: CanActivateChildFn = (route, state) => {
  return LUM_AUTH_GUARD(route, state);
};

export const LUM_AUTH_GUARD: CanActivateChildFn | CanActivateFn = async (
  route,
  state
) => {
  const router = inject(Router);
  const isAuthenticated = await inject(AuthService).isAuthenticated$();
  if (!isAuthenticated) {
    StorageUtils.set<string>('lum.last-route', state.url);
    router.navigate(['/login']);
  }
  return isAuthenticated;
};
