/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { LumAssignmentProcessing, LumProcessingCount } from '@lum-types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseDataMockService } from '../base-data/base-data.mock.service';
import { ProcessingService } from './processing.abstract';

@Injectable({
  providedIn: 'root',
})
export class ProcessingMockService
  extends BaseDataMockService<LumAssignmentProcessing>
  implements ProcessingService
{
  public processingCount$?: BehaviorSubject<LumProcessingCount | null> =
    new BehaviorSubject<LumProcessingCount | null>(null);

  constructor() {
    super();
    this._mockData = [
      {
        id: 1,
        clientProcessingId: '22',
        clientProcessingDetails: 'Details',
        processingParameters: [{ name: 'WIDTH', value: '100' }],
        connectorId: 1,
      },
    ];
  }

  public getProcessingCount(): Observable<LumProcessingCount> {
    const overview: LumProcessingCount = {
      assignedCount: 0,
      totalCount: 10,
    };
    this.processingCount$?.next(overview);
    return of(overview);
  }

  public createProcessingAssignment(
    processing: LumAssignmentProcessing
  ): Observable<LumAssignmentProcessing> {
    return of(processing);
  }

  public updateProcessingAssignment(
    processing: LumAssignmentProcessing
  ): Observable<LumAssignmentProcessing> {
    return of(processing);
  }

  public importAssignments(file: File | null): Observable<void> {
    return of(void 0);
  }
}
