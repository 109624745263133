import { StorageUtils } from '@lum-utils';

// for new language add also in app.module.ts in imports:
export type LumLanguage = 'en' | 'de' | 'cs';

export abstract class LanguageUtils {
  public static readonly availableLanguages: LumLanguage[] = ['en', 'de', 'cs'];
  public static readonly defaultLanguage: LumLanguage = 'en';

  public static getCurrentLanguage(): LumLanguage {
    const localStorageLanguage = StorageUtils.get<string>('lum.language');
    if (localStorageLanguage) {
      return localStorageLanguage as LumLanguage;
    }

    const browserLanguage = navigator.language?.substring(0, 2);
    const language =
      this.availableLanguages.find((e) => e === browserLanguage) ||
      this.defaultLanguage;
    StorageUtils.set<string>('lum.language', language);
    return language;
  }
}
