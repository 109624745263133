import { Pipe, PipeTransform } from '@angular/core';
import { getLabelByProcessingParameterName } from '@lum-helpers';
import { LumAccordionContent, LumProcessingParameter } from '@lum-types';
import { TranslateService } from '@ngx-translate/core';
import { LumFormatPipe } from '../format.pipe';

export type LumProcessingParametersTransformType =
  | 'accordion'
  | 'details'
  | 'filter';

@Pipe({
  name: 'processing-parameters',
  pure: false,
})
export class LumProcessingParametersPipe implements PipeTransform {
  constructor(
    private readonly formatPipe: LumFormatPipe,
    private readonly translateService: TranslateService
  ) {}

  public transform(
    processingParameters: LumProcessingParameter[],
    type: LumProcessingParametersTransformType
  ): string {
    switch (type) {
      case 'details':
        return this.processingParametersDetails(processingParameters);

      case 'filter':
        return this.convertProcessingParametersToFilter(processingParameters);
    }

    return '';
  }

  public convertProcessingParametersToAccordionContent(
    processingParameters?: LumProcessingParameter[]
  ): LumAccordionContent[] {
    return (
      processingParameters?.map((parameter) => ({
        label: getLabelByProcessingParameterName(parameter.name),
        value: this.formatPipe.transform(parameter.value, 'number'),
      })) ?? []
    );
  }

  private processingParametersDetails(
    processingParameters?: LumProcessingParameter[]
  ): string {
    return (
      processingParameters
        ?.map((param) => {
          return (
            this.translateService.instant(
              getLabelByProcessingParameterName(param.name)
            ) +
            ': ' +
            this.formatPipe.transform(param.value, 'number')
          );
        })
        .join(', ') ?? ''
    );
  }

  private convertProcessingParametersToFilter(
    processingParameters?: LumProcessingParameter[]
  ): string {
    if (!processingParameters) {
      return '';
    }

    return processingParameters
      .map((parameter) => `${parameter.name}:${parameter.value}`)
      .join(',');
  }
}
