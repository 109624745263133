import { LumUnit } from '../unit.type';
import { LumClientProduct } from './client-product.type';
import { LumPlatformProduct } from './platform-product.type';

export type LumProductCategory = LumPlatformProduct['productCategory'];

export const LUM_PRODUCT_CATEGORY = [
  'GLASS',
  'SPACER',
  'INTERLAYER',
  'SEALING',
  'GEORGIAN_BAR',
  'GAS',
  'CAST_RESIN',
] as const;

export type LumProductCategoryMetaData = {
  productCategory: LumProductCategory;
  assignedCount: number;
  totalCount: number;
};

export type LumProductCategorySlug =
  | 'glass'
  | 'spacer'
  | 'interlayer'
  | 'sealing'
  | 'georgian-bar'
  | 'gas'
  | 'cast-resin'
  | 'processing';

export type LumProductDescriptionDefinition = {
  key: string;
  label: string;
  unit?: LumUnit;
};

export type LumCombinedProduct = LumClientProduct & LumPlatformProduct;
