<label
  class="flex items-start lum-radio-container"
  [ngClass]="getRadioClasses()"
  [for]="htmlId"
>
  <input
    type="radio"
    name="radio_{{ groupId }}"
    [id]="htmlId"
    [value]="key"
    [checked]="isChecked()"
    [disabled]="isDisabled"
    (change)="onChange($event)"
    class="mt-1 form-radio"
    [attr.data-cy]="dataCy"
  />
  <div class="grow">
    <span
      *ngIf="label"
      class="pl-2 pr-1 font-semibold typo-label-sm"
      [ngClass]="{ 'text-gray-400': isDisabled }"
    >
      {{ label }}
    </span>

    <ng-content></ng-content>
  </div>
</label>
