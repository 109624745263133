import { ErrorHandler, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogInfoComponent } from '@lum-dialogs/dialog-info/dialog-info.component';
import { LumLogger } from '../../utils/logger.utils';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  constructor(protected readonly dialog: MatDialog) {}

  public handleError(error: Error): void {
    LumLogger.error('handle error', error);

    if (this.isChunkLoadError(error)) {
      const ref = this.dialog.open(DialogInfoComponent, {
        disableClose: true,
        data: {
          headline: 'lum.error.chunk-error.headline',
          content: 'lum.error.chunk-error.content',
          cta: 'lum.error.chunk-error.cta',
        },
      });

      ref.afterClosed().subscribe(() => {
        window.location.reload();
      });
    }
  }

  private isChunkLoadError(error: Error): boolean {
    if (error && error.name) {
      return error.name.indexOf('ChunkLoadError') !== -1;
    }
    return false;
  }
}
