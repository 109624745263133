import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@lum-ui/icon';
import { SpinnerModule } from '@lum-ui/spinner';
import { ButtonComponent } from './button.component';

@NgModule({
  declarations: [ButtonComponent],
  imports: [CommonModule, IconModule, SpinnerModule],
  exports: [ButtonComponent],
})
export class ButtonModule {}
