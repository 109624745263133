import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationModule } from '@lum-ui/notification';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from '../checkbox';
import { ErrorModule } from '../error';
import { InputModule } from '../input';
import { MultiSelectModule } from '../multi-select';
import { RadioModule } from '../radio';
import { RadioGroupModule } from '../radio-group';
import { SelectModule } from '../select';
import { TextareaModule } from '../textarea';
import { ToggleModule } from '../toggle';
import { FormFieldComponent } from './form-field.component';

@NgModule({
  declarations: [FormFieldComponent],
  imports: [
    CommonModule,
    InputModule,
    ErrorModule,
    SelectModule,
    CheckboxModule,
    RadioGroupModule,
    ToggleModule,
    TextareaModule,
    NotificationModule,
    MultiSelectModule,
    TranslateModule,
  ],
  exports: [FormFieldComponent, RadioModule],
})
export class FormFieldModule {}
