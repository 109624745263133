import { DcuplList } from '@dcupl/core';
import { LumAssignment, LumAssignmentCategory } from '@lum-types';
import { Observable } from 'rxjs';

export abstract class AssignmentService {
  public abstract dcuplList?: DcuplList<LumAssignment>;
  public abstract importAssignments(
    file: File | null,
    assignmentCategory?: LumAssignmentCategory
  ): Observable<void>;
}
