import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { LumFormField } from '@lum-form';
import { getUniqueId } from '@lum-helpers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lum-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent implements OnInit, OnDestroy {
  @Input({ required: true }) control!: FormControl;
  @Input({ required: true }) formField!: LumFormField;
  @Input() isDisabled: boolean = false;
  @Input() isReadonly: boolean = false;

  public htmlId = getUniqueId();

  @Input() isOptionalVisible = false;

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.isOptionalVisible = !this.control.hasValidator(Validators.required);
    this.isDisabled = this.control.disabled;

    this.listenForStatusChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForStatusChanges(): void {
    this.subscriptions.add(
      this.control.parent?.statusChanges.subscribe(() => {
        this.cdRef.detectChanges();
      })
    );
  }

  private isTouched(): boolean {
    return !!this.control.touched;
  }

  public getErrors(): ValidationErrors | undefined {
    if (this.control.errors) {
      return this.control.errors;
    }

    return undefined;
  }

  public shouldShowErrors(): boolean {
    return this.isTouched() && !!this.getErrors();
  }
}
