import { LumBusinessCaseSummaryBuildupEntryProduct } from '@lum-types';
import { neverError } from '../never.helper';

export function getLabelByBuildupProductProperty(
  key: keyof LumBusinessCaseSummaryBuildupEntryProduct
): string {
  switch (key) {
    case 'id':
      return 'ID';

    case 'productCategory':
      return 'lum.business-case.detail.data-view.header.productCategory';

    case 'customerProductId':
    case 'supplierProductId':
      return 'lum.business-case.detail.data-view.header.my-productId';

    case 'customerProductDetails':
    case 'supplierProductDetails':
      return 'lum.business-case.detail.data-view.header.my-productDescription';

    case 'platformProductId':
      return 'lum.business-case.detail.data-view.header.product-id';

    case 'platformProductDetails':
      return 'lum.business-case.detail.data-view.header.product-description';

    case 'alternativePlatformProductId':
      return 'Alternative Platform Product ID';

    default:
      throw neverError(key);
  }
}
