import { Injectable } from '@angular/core';
import { LumClientProduct, LumProductCategory } from '@lum-types';
import { first } from 'lodash-es';
import { firstValueFrom } from 'rxjs';
import { ProductService } from './api';

@Injectable({
  providedIn: 'root',
})
export class AlternativeProductsService {
  public clientProducts: { [key: string]: { product: LumClientProduct } } = {};

  constructor(private readonly productService: ProductService) {}

  public async fetchClientProductByAlternativeProductId(
    alternativeProductId: string,
    productCategory: LumProductCategory
  ): Promise<LumClientProduct> {
    const cachedProduct = this.clientProducts[alternativeProductId];
    if (cachedProduct) {
      return cachedProduct.product;
    }

    const products = await firstValueFrom(
      this.productService.getAll(
        {
          filters: [
            {
              propertyName: 'productCategory',
              value: productCategory,
            },
            {
              propertyName: 'platformProductId',
              value: alternativeProductId,
            },
          ],
        },
        undefined,
        undefined,
        true
      )
    );

    const product = first(products.content);
    if (product) {
      this.clientProducts[alternativeProductId] = { product };
      return this.clientProducts[alternativeProductId]?.product;
    }

    return Promise.reject();
  }
}
