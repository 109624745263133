import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LUM_ENVIRONMENT_GUARD } from '@lum-guards';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./lumeso/lumeso.module').then((m) => m.LumesoModule),
  },
  {
    path: 'designsystem',
    loadChildren: () =>
      import('./designsystem-nds/designsystem-nds.module').then(
        (m) => m.DesignsystemNdsModule
      ),
    canActivate: [LUM_ENVIRONMENT_GUARD],
  },
  //Wild Card Route for 404 request
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
