/* eslint-disable no-console */
export enum LUM_LOG_LEVEL {
  None,
  Error,
  Warning,
  Info,
  Time,
  TimeEnd,
  Count,
  All,
}

export abstract class LumLogger {
  private static logLevel = LUM_LOG_LEVEL.Info;

  public static setLogLevel(logLevel: LUM_LOG_LEVEL): void {
    this.logLevel = logLevel;
  }

  public static count(message: string, ...params: unknown[]): void {
    this.logInternal(LUM_LOG_LEVEL.Count, message, params);
  }

  public static time(message: string, ...params: unknown[]): void {
    this.logInternal(LUM_LOG_LEVEL.Time, message, params);
  }

  public static timeEnd(message: string, ...params: unknown[]): void {
    this.logInternal(LUM_LOG_LEVEL.TimeEnd, message, params);
  }

  public static log(message: string, ...params: unknown[]): void {
    this.logInternal(LUM_LOG_LEVEL.Info, message, params);
  }

  public static warn(message: string, ...params: unknown[]): void {
    this.logInternal(LUM_LOG_LEVEL.Warning, message, params);
  }

  public static error(message: string, ...params: unknown[]): void {
    this.logInternal(LUM_LOG_LEVEL.Error, message, params);
  }

  private static logInternal(
    level: LUM_LOG_LEVEL,
    message: string,
    params: unknown[]
  ): void {
    if (message && level <= this.logLevel) {
      this.getLogFunction(level)(message, ...params);
    }
  }

  private static getLogFunction(
    level: LUM_LOG_LEVEL
  ): (message: string, ...params: unknown[]) => void {
    switch (level) {
      case LUM_LOG_LEVEL.Error: {
        return console.error;
      }
      case LUM_LOG_LEVEL.Warning: {
        return console.warn;
      }
      case LUM_LOG_LEVEL.Time: {
        return console.time;
      }
      case LUM_LOG_LEVEL.TimeEnd: {
        return console.timeEnd;
      }
      case LUM_LOG_LEVEL.Count: {
        return console.count;
      }
      default: {
        return console.log;
      }
    }
  }
}
