import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { LUM_ENV } from './environments/environment';

import { Amplify } from 'aws-amplify';

if (LUM_ENV.production) {
  enableProdMode();
}

// set amplify configuration for authentication
Amplify.configure({ Auth: { Cognito: LUM_ENV.cognito } });

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
