import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LumFormFieldExtComponent, LumFormValue } from '@lum-form';
import { getUniqueId } from '@lum-helpers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lum-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent<OK extends LumFormValue>
  extends LumFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) key!: OK;
  @Input() label?: string;
  @Input({ required: true }) groupId!: string;
  @Input() isDisabled: boolean = false;
  @Input() size: 'M' | 'L' = 'M';

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
    this.htmlId = getUniqueId();
  }

  ngOnInit(): void {
    this.listenForFormChange();
  }

  ngOnDestroy(): void {}

  private listenForFormChange(): void {
    this.subscriptions.add(
      this.control?.valueChanges.subscribe(() => {
        this.cdRef.detectChanges();
      })
    );
  }

  public isChecked(): boolean {
    return this.control?.value === this.key;
  }

  public getRadioClasses(): string[] {
    const classes = [];

    if (this.isChecked()) {
      classes.push('is-active');
    }

    if (this.size === 'L') {
      classes.push('lum-radio-container--lg');
    }

    return classes;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onChange(event: any): void {
    const value = event.target.value;

    if (value === 'true') {
      this.control?.patchValue(true);
    } else if (value === 'false') {
      this.control?.patchValue(false);
    } else {
      this.control?.patchValue(value);
    }

    this.control?.markAsTouched();
    this.control?.markAsDirty();
  }
}
