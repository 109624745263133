<div
  class="absolute inset-x-0 top-0 z-0 w-full bg-blue-500 h-80 lum-header"
></div>

<div class="relative z-10 flex flex-col gap-4">
  <div class="mb-4">
    <ng-content select="#backBtn"></ng-content>
  </div>

  <header [ngClass]="marginBottom">
    <h3 class="text-white typo-h3">
      <ng-content select="#headline"></ng-content>
    </h3>
  </header>

  <ng-content></ng-content>
</div>
