import { Injectable } from '@angular/core';
import {
  LumAssignmentCategory,
  LumClientProduct,
  LumProductCategory,
  LumProductOverview,
} from '@lum-types';
import { LumLogger } from '@lum-utils';
import { BehaviorSubject, Observable, firstValueFrom, tap } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService } from '../api.service';
import { BaseDataAPIService } from '../base-data/base-data.api.service';
import { ProductService } from './product.abstract';

@Injectable({
  providedIn: 'root',
})
export class ProductAPIService
  extends BaseDataAPIService<LumClientProduct>
  implements ProductService
{
  public productOverview$?: BehaviorSubject<LumProductOverview | null> =
    new BehaviorSubject<LumProductOverview | null>(null);

  public selectedProductCategory$: BehaviorSubject<LumProductCategory | null> =
    new BehaviorSubject<LumProductCategory | null>(null);

  constructor(
    public override apiService: ApiService,
    public override dcuplService: DcuplService
  ) {
    super(apiService, dcuplService);
    this._baseApiRoute = 'client-product';
  }

  public getProductOverview(): Observable<LumProductOverview> {
    return this.apiService
      .get<LumProductOverview>({
        endpoint: this._baseApiRoute,
        path: 'overview',
      })
      .pipe(
        tap((response) => {
          this.productOverview$?.next(response);
        })
      );
  }

  public updateProductAssignment(
    clientProduct: LumClientProduct
  ): Observable<LumClientProduct> {
    return this.apiService.put<LumClientProduct>({
      endpoint: this._baseApiRoute,
      path: '{id}',
      id: clientProduct.id,
      body: {
        ...clientProduct,
      },
    });
  }

  public importAssignments(
    file: File | null,
    assignmentCategory?: LumAssignmentCategory
  ): Observable<void> {
    if (!assignmentCategory || !file) {
      LumLogger.error(
        'Missing category or file: importAssignments()',
        assignmentCategory
      );
      throw new Error('Missing category or file');
    }

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.apiService
      .post<void>({
        endpoint: this._baseApiRoute,
        path: '{id}/file-upload',
        id: assignmentCategory,
        body: formData,
        responseType: 'text',
      })
      .pipe(
        tap(async () => {
          await firstValueFrom(this.getProductOverview());
        })
      );
  }
}
