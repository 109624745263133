import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { DcuplList } from '@dcupl/core';
import { LumAssignment, LumAssignmentCategory, LumClientProduct, LumDataSource, LumProductCategory, LumProductOverview } from '@lum-types';
import { StorageUtils } from '@lum-utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService } from '../api.service';
import { AssignmentService } from '../assignment/assignment.service';
import { BaseDataService } from '../base-data/base-data.abstract';
import { ProductAPIService } from './product.api.service';
import { ProductMockService } from './product.mock.service';

export abstract class ProductService extends BaseDataService<LumClientProduct> implements AssignmentService {
  public abstract productOverview$?: BehaviorSubject<LumProductOverview | null>;
  public abstract selectedProductCategory$: BehaviorSubject<LumProductCategory | null>;
  public abstract dcuplList?: DcuplList<LumAssignment>;
  public abstract getProductOverview(): Observable<LumProductOverview>;
  public abstract updateProductAssignment(clientProduct: LumClientProduct): Observable<LumClientProduct>;
  public abstract importAssignments(file: File | null, assignmentCategory?: LumAssignmentCategory): Observable<void>;
}

export function ProductFactory(apiService: ApiService, dcuplService: DcuplService): ProductService {
  if (StorageUtils.get<LumDataSource>('lum.data-source') === 'mock-data') {
    return new ProductMockService();
  }

  return new ProductAPIService(apiService, dcuplService);
}

export const LUM_PRODUCT_PROVIDER: FactoryProvider = {
  provide: ProductService,
  useFactory: ProductFactory,
  deps: [ApiService, DcuplService, HttpClient],
};
