import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@lum-ui/button';
import { IconModule } from '@lum-ui/icon';
import { DialogComponent } from './dialog.component';

@NgModule({
  declarations: [DialogComponent],
  imports: [CommonModule, IconModule, ButtonModule, MatDialogModule],
  exports: [DialogComponent],
})
export class DialogModule {}
