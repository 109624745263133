import { Projection } from '@dcupl/common';
import { LumApiPath, LumDataView, LumFilter, LumPagination, LumSort } from '@lum-types';
import { Observable } from 'rxjs';

export type LumBaseDataParams<T> = {
  path?: LumApiPath;
  pagination?: LumPagination;
  filters?: LumFilter<T>[];
  sort?: LumSort<T>;
  id?: number | string;
  resetDcuplData?: boolean;
};

export abstract class BaseDataService<T> {
  public abstract getAll(params?: LumBaseDataParams<T>, dcuplModelKey?: string, dcuplProjection?: Projection<T>, skipPathAll?: boolean): Observable<LumDataView<T>>;
  public abstract getEntity(): Observable<T>;
  public abstract getEntityById(id: string): Observable<T>;
  public abstract resetQueryParams(): void;
}
