<lum-dialog [disableClose]="dialogRef.disableClose">
  <ng-container id="header">{{ data.headline | translate }}</ng-container>

  <p [innerHTML]="data.content | translate"></p>

  <ng-container id="footer">
    <div class="flex items-center justify-end">
      <button
        *ngIf="data.cta"
        lum-button
        type="button"
        (click)="onCloseClick()"
      >
        {{ data.cta | translate }}
      </button>
    </div>
  </ng-container>
</lum-dialog>
