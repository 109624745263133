import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'lum-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  @Input() isFooterVisible = true;
  @Input() disableClose? = true;

  constructor(private dialogRef: MatDialog) {}

  public onCloseClick(): void {
    this.dialogRef.closeAll();
  }
}
