import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { LumAlternativeProduct, LumBusinessRelation, LumBusinessRelationRequest, LumBusinessRelationState, LumDataSource, LumDataView, LumStatisticsResponse } from '@lum-types';
import { StorageUtils } from '@lum-utils';
import { Observable } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService } from '../api.service';
import { BaseDataService, LumBaseDataParams } from '../base-data/base-data.abstract';
import { BusinessRelationAPIService } from './business-relation.api.service';
import { BusinessRelationMockService } from './business-relation.mock.service';

export abstract class BusinessRelationService extends BaseDataService<LumBusinessRelation> {
  public abstract createAlternativeProduct(alternativeProduct: LumAlternativeProduct): Observable<LumAlternativeProduct>;
  public abstract getAlternativeProducts(businessRelationId: number, params?: LumBaseDataParams<LumAlternativeProduct>): Observable<LumDataView<LumAlternativeProduct>>;
  public abstract createBusinessRelation(request: LumBusinessRelationRequest): Observable<void>;
  public abstract updateBusinessRelationState(id: number, state: LumBusinessRelationState): Observable<void>;
  public abstract getBusinessRelationTaskCount(): Observable<LumStatisticsResponse>;
}

export function BusinessRelationFactory(apiService: ApiService, dcuplService: DcuplService): BusinessRelationService {
  if (StorageUtils.get<LumDataSource>('lum.data-source') === 'mock-data') {
    return new BusinessRelationMockService();
  }

  return new BusinessRelationAPIService(apiService, dcuplService);
}

export const LUM_BUSINESS_RELATION_PROVIDER: FactoryProvider = {
  provide: BusinessRelationService,
  useFactory: BusinessRelationFactory,
  deps: [ApiService, DcuplService, HttpClient],
};
