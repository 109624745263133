import { Pipe, PipeTransform } from '@angular/core';
import { neverError } from '@lum-helpers';
import { TranslateService } from '@ngx-translate/core';
import { LumFormat, LumFormatOptions } from '../types/format.type';
import { LumLocaleDatePipe } from './locale-date.pipe';
import { LumPluralTranslatePipe } from './pluralTranslate.pipe';

@Pipe({
  name: 'format',
  pure: false,
})
export class LumFormatPipe implements PipeTransform {
  constructor(
    private localeDatePipe: LumLocaleDatePipe,
    private translateService: TranslateService,
    private pluralTranslatePipe: LumPluralTranslatePipe
  ) {}

  public transform(
    value: unknown,
    format?: LumFormat,
    options?: LumFormatOptions
  ): string {
    if (!value) {
      return '';
    }

    if (!format) {
      return String(value);
    }

    switch (format) {
      case 'date':
        return this.date(value);

      case 'datetime':
        return this.dateTime(value, options);

      case 'number':
        return this.number(value, options);

      case 'translate':
        return this.translate(value);

      case 'plural-translate':
        return this.pluralTranslate(value, options);

      default:
        throw neverError(format);
    }
  }

  private date(value: unknown): string {
    if (value instanceof Date || typeof value === 'string') {
      return this.localeDatePipe.transform(value, ['dd. MMM yyyy']);
    }
    return String(value);
  }

  private dateTime(value: unknown, options?: LumFormatOptions): string {
    if (value instanceof Date || typeof value === 'string') {
      return this.localeDatePipe.transform(
        value,
        ['dd. MMM yyyy', 'HH:mm'],
        undefined,
        options?.delimiter
      );
    }
    return String(value);
  }

  private number(value: unknown, options?: LumFormatOptions): string {
    if (typeof value === 'number' || typeof value === 'string') {
      return Number(value).toFixed(2);
    }

    return '-';
  }

  private translate(value: unknown): string {
    if (typeof value == 'string') {
      return this.translateService.instant(value);
    }
    return String(value);
  }

  private pluralTranslate(value: unknown, options?: LumFormatOptions): string {
    if (typeof value == 'string') {
      if (options?.count) {
        value = this.pluralTranslatePipe.transform(value, options.count);
      }
      return this.translateService.instant(value as string, {
        count: options?.count,
      });
    }
    return String(value);
  }
}
