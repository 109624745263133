import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { LUM_ENVIRONMENT, LumEnvironmentInterface } from '@lum-types';

@Component({
  selector: 'lum-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  public footerLinks = [];

  constructor(
    @Inject(LUM_ENVIRONMENT)
    public readonly environment: LumEnvironmentInterface
  ) {}

  public trackBy(index: number, link: string): string {
    return link;
  }
}
