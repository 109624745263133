/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { LumClientBuildup } from '@lum-types';
import { Observable, of } from 'rxjs';
import { BaseDataMockService } from '../base-data/base-data.mock.service';
import { BuildupService } from './buildup.abstract';

@Injectable({
  providedIn: 'root',
})
export class BuildupMockService
  extends BaseDataMockService<LumClientBuildup>
  implements BuildupService
{
  private readonly buildups: LumClientBuildup[] = [
    {
      id: 1,
      clientBuildupId: '1 buildup',
      clientBuildupDetails: '1 buildup details',
      connectorId: 1,
    },
    {
      id: 2,
      clientBuildupId: '2 buildup',
      clientBuildupDetails: '2 buildup details',
      connectorId: 2,
    },
    {
      id: 3,
      clientBuildupId: '3 buildup',
      connectorId: 3,
    },
  ];

  constructor() {
    super();
    this._mockData = this.buildups;
  }

  public createClientBuildup(buildup: LumClientBuildup): Observable<void> {
    return this.delayResponse(of(void 0));
  }

  public importBuildups(file: File | null): Observable<void> {
    return this.delayResponse(of(void 0));
  }
}
