import {
  LumBusinessRelationState,
  LumHighlightType,
  LumIcon,
} from '@lum-types';
import { neverError } from '../never.helper';

export function convertBusinessRelationStateToLocoKey(
  businessRelationSate: LumBusinessRelationState
): string {
  switch (businessRelationSate) {
    case 'ACTIVE':
      return 'lum.business-relations.state.active.title';

    case 'PENDING':
      return 'lum.business-relations.state.pending.title';

    case 'CLOSED':
      return 'lum.business-relations.state.closed.title';

    case 'SUSPENDED':
      return 'lum.business-relations.state.suspended.title';

    default:
      throw neverError(businessRelationSate);
  }
}

export function getBusinessRelationStateLevel(
  businessRelationSate: LumBusinessRelationState
): LumHighlightType {
  switch (businessRelationSate) {
    case 'ACTIVE':
      return 'success';

    case 'PENDING':
      return 'info';

    case 'CLOSED':
    case 'SUSPENDED':
      return 'warning';

    default:
      throw neverError(businessRelationSate);
  }
}

export function getBusinessRelationStateIcon(
  businessRelationSate: LumBusinessRelationState
): LumIcon {
  switch (businessRelationSate) {
    case 'ACTIVE':
      return 'check-circle-16';

    case 'PENDING':
      return 'pending-16';

    case 'CLOSED':
    case 'SUSPENDED':
      return 'decline-16';

    default:
      throw neverError(businessRelationSate);
  }
}
