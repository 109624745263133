/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { LumConnector, LumConnectorCredentials } from '@lum-types';
import { subBusinessDays } from 'date-fns';
import { Observable, map, of } from 'rxjs';
import { BaseDataMockService } from '../base-data/base-data.mock.service';
import { ConnectorService } from './connector.abstract';

@Injectable({
  providedIn: 'root',
})
export class ConnectorMockService
  extends BaseDataMockService<LumConnector>
  implements ConnectorService
{
  private readonly connectors: LumConnector[] = [
    {
      id: 1,
      disabled: true,
      name: 'Connector 1',
      companyId: 1,
      company: { name: 'Company Name', site: 'Site', branch: 'Branch' },
      appClientId: '123',
    },
    {
      id: 2,
      name: 'Connector 2',
      description: 'Connector 2 description',
      disabled: false,
      companyId: 2,
      company: { name: 'Company Name 2' },
      lastActivity: subBusinessDays(new Date(), 1),
    },
  ];

  constructor() {
    super();
    this._mockData = this.connectors;
  }

  public generateConnectorCredentials(
    connectorId?: number
  ): Observable<LumConnectorCredentials> {
    return of({
      appClientId: '123',
      appClientSecret: (Math.random() + 1).toString(36).substring(2),
    }).pipe(map((response) => ({ ...response, isMasked: false })));
  }

  public disableConnector(
    connectorId: number,
    disabled: boolean
  ): Observable<void> {
    return of();
  }
}
