import { Component, OnInit } from '@angular/core';
import { LUM_ENV } from '@lum-environment';
import { IconsRegistry } from '@lum-services';
import { completeIconSet } from '@lum-types';
import { LanguageUtils, LumLogger } from '@lum-utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lum-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private readonly iconRegistry: IconsRegistry,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    LumLogger.setLogLevel(LUM_ENV.logLevel);
    this.iconRegistry.registerIcons(completeIconSet);
    this.initLanguage();
  }

  private initLanguage(): void {
    const language = LanguageUtils.getCurrentLanguage();
    this.translateService.setDefaultLang(LanguageUtils.defaultLanguage);
    this.translateService.use(language);
  }
}
