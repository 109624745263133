import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MultiSelectService {
  private activeMultiSelect$$ = new BehaviorSubject<string | null>(null);

  constructor() {}

  get activeMultiSelect$(): Observable<string | null> {
    return this.activeMultiSelect$$.asObservable().pipe(distinctUntilChanged());
  }

  public setActiveMultiSelect(value: string | null): void {
    this.activeMultiSelect$$.next(value);
  }
}
