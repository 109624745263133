<div>
  <label
    [for]="htmlId"
    class="flex items-baseline mb-1 font-bold typo-label-xs"
    [class.text-gray-400]="isDisabled"
    *ngIf="formField.label"
  >
    <span class="mr-1" [innerHTML]="formField.label | translate"></span>
    <span *ngIf="isOptionalVisible">(optional)</span>
  </label>

  <div class="grow">
    <ng-container *ngIf="formField.type === 'text'">
      <lum-input
        [control]="control"
        [autocomplete]="formField.autocomplete ?? 'off'"
        [placeholder]="formField.placeholder ?? ''"
        [type]="formField.textType ?? 'text'"
        [htmlId]="htmlId"
        [isDisabled]="isDisabled"
        [dataCy]="formField.dataCy"
      ></lum-input>
    </ng-container>

    <ng-container *ngIf="formField.type === 'textarea'">
      <lum-textarea
        [control]="control"
        [htmlId]="htmlId"
        [isDisabled]="isDisabled"
        [dataCy]="formField.dataCy"
      ></lum-textarea>
    </ng-container>

    <ng-container *ngIf="formField.type === 'select'">
      <lum-select
        [control]="control"
        [options]="formField.options"
        [placeholder]="formField.placeholder"
        [htmlId]="htmlId"
        [isDisabled]="isDisabled"
        [dataCy]="formField.dataCy"
      ></lum-select>
    </ng-container>

    <ng-container *ngIf="formField.type === 'multi-select'">
      <lum-multi-select
        [control]="control"
        [options]="formField.options"
        [placeholder]="formField.placeholder"
        [htmlId]="htmlId"
        [isDisabled]="isDisabled"
        [dataCy]="formField.dataCy"
      ></lum-multi-select>
    </ng-container>

    <ng-container *ngIf="formField.type === 'checkbox'">
      <lum-checkbox
        [control]="control"
        [innerLabel]="formField.innerLabel"
        [htmlId]="htmlId"
        [isReadonly]="isReadonly || isDisabled"
        [dataCy]="formField.dataCy"
      ></lum-checkbox>
    </ng-container>

    <ng-container *ngIf="formField.type === 'radio-group'">
      <lum-radio-group
        [control]="control"
        [options]="formField.options"
        [htmlId]="htmlId"
        [isDisabled]="isReadonly || isDisabled"
        [isFlex]="formField.isFlex ?? false"
        [dataCy]="formField.dataCy"
      ></lum-radio-group>
    </ng-container>

    <ng-container *ngIf="formField.type === 'toggle'">
      <lum-toggle
        [control]="control"
        [innerLabel]="formField.innerLabel"
        [additionalInfo]="formField.additionalInfo"
        [htmlId]="htmlId"
        [isDisabled]="isDisabled"
        [dataCy]="formField.dataCy"
      ></lum-toggle>
    </ng-container>

    <div *ngIf="shouldShowErrors()" class="mt-1">
      <lum-error [errors]="getErrors()"></lum-error>
    </div>

    <div *ngIf="formField.info">
      <div class="mt-4">
        <lum-notification type="info">{{ formField.info }}</lum-notification>
      </div>
    </div>
  </div>
</div>
