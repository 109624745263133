import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  LumFormFieldExtComponent,
  LumFormValue,
  LumSelectOption,
} from '@lum-form';

@Component({
  selector: 'lum-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SelectComponent<
  OptionKey extends LumFormValue,
> extends LumFormFieldExtComponent {
  @Input({ required: true }) options!: LumSelectOption<OptionKey>[];
  @Input() placeholder?: string;
  @Input() isDisabled? = false;

  public isExpanded = false;

  constructor() {
    super();
  }
}
