/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import {
  LumBusinessCase,
  LumBusinessCaseStateHistoryEntry,
  LumBusinessCaseSummaryBuildup,
  LumBusinessCaseSummaryBuildupEntry,
  LumBusinessCaseSummaryProcessing,
  LumBusinessCaseSummaryProduct,
  LumBusinessRelationRole,
  LumDataView,
  LumStatisticsResponse,
} from '@lum-types';
import { map, Observable, of } from 'rxjs';
import { LumRequestParams } from '../api.service';
import { LumBaseDataParams } from '../base-data/base-data.abstract';
import { BaseDataMockService } from '../base-data/base-data.mock.service';
import { BusinessCaseService } from './business-case.abstract';

@Injectable({
  providedIn: 'root',
})
export class BusinessCaseMockService
  extends BaseDataMockService<LumBusinessCase>
  implements BusinessCaseService
{
  private readonly businessCases: LumBusinessCase[] = [
    {
      id: 1287,
      clientOrderId: 'Cypress Business Case 770453',
      created: new Date('2024-01-31T07:21:29.408701Z'),
      customerId: 3,
      supplierId: 2,
      unassignedProductCount: 1,
      businessRelationId: 1,
      partnerCompanyName: 'Partner Company Name',
      buildupCount: 3,
      state: 'AVAILABLE_FOR_SUPPLIER',
    },
    {
      id: 1286,
      clientOrderId: 'Cypress Business Case 44927',
      created: new Date('2024-01-31T07:20:45.553616Z'),
      customerId: 2,
      supplierId: 1,
      buildupCount: 0,
      businessRelationId: 1,
      state: 'CREATED',
    },
    {
      id: 1285,
      clientOrderId: 'Cypress Business Case 923037',
      created: new Date('2024-01-31T07:20:15.312115Z'),
      customerId: 1,
      unassignedProductCount: 1,
      unassignedProcessingCount: 1,
      businessRelationId: 1,
      state: 'CUSTOMER_CONVERSION_FAILURE',
    },
    {
      id: 1284,
      clientOrderId: 'Cypress Business Case 242857',
      created: new Date('2024-01-31T07:19:46.528940Z'),
      customerId: 1,
      unassignedProcessingCount: 1,
      businessRelationId: 1,
      state: 'FILE_DOWNLOAD_FAILURE',
    },
    {
      id: 1283,
      clientOrderId: 'Cypress Business Case 418307',
      created: new Date('2024-01-31T07:19:12.039504Z'),
      customerId: 1,
      deliveryDate: new Date('2024-02-31T07:19:12.039504Z'),
      unassignedProductCount: 1,
      businessRelationId: 1,
      state: 'FILE_UPLOAD_FAILURE',
    },
    {
      id: 1282,
      clientOrderId: 'Cypress Business Case 175788',
      created: new Date('2024-01-31T07:18:36.935162Z'),
      customerId: 2,
      unassignedProductCount: 1,
      businessRelationId: 1,
      state: 'ASSIGNED_TO_PLATFORM',
    },
    {
      id: 1281,
      clientOrderId: 'Cypress Business Case 737',
      created: new Date('2024-01-31T07:16:31.268522Z'),
      customerId: 1,
      unassignedProductCount: 0,
      businessRelationId: 1,
      state: 'ASSIGNED_TO_SUPPLIER',
    },
    {
      id: 1280,
      clientOrderId: 'Cypress Business Case 388',
      created: new Date('2024-01-31T07:16:20.324584Z'),
      customerId: 1,
      unassignedProductCount: 0,
      businessRelationId: 1,
      state: 'ASSIGNMENT_FAILURE_CUSTOMER',
    },
    {
      id: 1279,
      clientOrderId: 'Cypress Business Case 459',
      created: new Date('2024-01-31T07:15:16.399924Z'),
      customerId: 1,
      unassignedProductCount: 2,
      businessRelationId: 1,
      state: 'ASSIGNMENT_FAILURE_SUPPLIER',
    },
    {
      id: 1278,
      clientOrderId: 'Cypress Business Case 571',
      created: new Date('2024-01-27T07:15:08.026350Z'),
      customerId: 1,
      businessRelationId: 1,
      state: 'ROUTED',
    },
    {
      id: 1279,
      clientOrderId: 'Cypress Business Case 489',
      created: new Date('2024-01-27T09:15:08.026350Z'),
      customerId: 1,
      businessRelationId: 1,
      state: 'ROUTING_FAILURE',
    },
    {
      id: 1280,
      clientOrderId: 'Cypress Business Case 345',
      created: new Date('2024-01-27T05:15:08.026350Z'),
      customerId: 1,
      businessRelationId: 1,
      state: 'SUPPLIER_CONNECTOR_DISABLED',
    },
    {
      id: 1281,
      clientOrderId: 'Cypress Business Case 32',
      created: new Date('2024-01-27T03:15:08.026350Z'),
      customerId: 1,
      businessRelationId: 1,
      state: 'SUPPLIER_RECEIVING_FAILURE',
    },
    {
      id: 12344543,
      clientOrderId: 'Cypress Business Case CUSTOMER_ORDER_RECEIVED',
      created: new Date('2024-01-27T03:11:08.026350Z'),
      customerId: 1,
      businessRelationId: 1,
      state: 'CUSTOMER_ORDER_RECEIVED',
    },
  ];

  private readonly businessCaseSummaryProducts: LumBusinessCaseSummaryProduct[] =
    [
      {
        id: 1,
        clientProductId: '2369',
        productCategory: 'GLASS',
        clientProductDetails: 'Test',
        alternativePlatformProductId: 'acue-ab56h-dt6',
        quantity: 1,
      },
      {
        id: 2,
        productCategory: 'GLASS',
        quantity: 1,
        platformProductId: 'bt77-bdg4q-ga8',
      },
      {
        clientProductId: '2365',
        productCategory: 'GLASS',
        platformProductId: 'plat156750',
        quantity: 2,
      },
      {
        clientProductId: '2362',
        productCategory: 'GLASS',
        platformProductId: 'bt77-bdg4q-ga8',
        quantity: 3,
      },
      {
        productCategory: 'INTERLAYER',
        quantity: 4,
        platformProductId: 'xxx-2',
      },
    ];

  private readonly businessCaseSummaryProcessings: LumBusinessCaseSummaryProcessing[] =
    [
      {
        id: 1,
        clientProcessingId: '2369',
        clientProcessingDetails: 'Test',
        quantity: 1,
      },
      {
        id: 2,
        quantity: 1,
        clientProcessingId: '2369',
        clientProcessingDetails: 'acue-ab56h-dt6',
      },
      {
        id: 3,
        clientProcessingId: '2365',
        platformProcessingId: 'plat156750',
        quantity: 2,
      },
      {
        id: 4,
        clientProcessingId: '2362',
        platformProcessingId: 'EdgeworkFacet-Edge',
        quantity: 3,
      },
      {
        id: 5,
        quantity: 4,
        platformProcessingId: 'xxx-2',
      },
    ];

  private readonly businessCaseSummaryBuildups: LumBusinessCaseSummaryBuildup[] =
    [
      {
        id: 1,
        clientBuildupId: '2369',
        clientBuildupDetails: 'clientBuildupDetails 2369',
        quantity: 1,
      },
      {
        id: 2,
        clientBuildupDetails: 'clientBuildupDetails 2369',
        quantity: 1,
      },
      {
        id: 3,
        clientBuildupId: '6489',
        clientBuildupDetails: 'clientBuildupDetails 6489',
        quantity: 3,
      },
    ];

  constructor() {
    super();
    this._mockData = this.businessCases;
  }

  public getBusinessCaseSummaryBuildup(
    id: number,
    params?: LumBaseDataParams<LumBusinessCaseSummaryBuildup>
  ): Observable<LumDataView<LumBusinessCaseSummaryBuildup>> {
    return of({
      content: this.businessCaseSummaryBuildups,
      total: this.businessCaseSummaryBuildups.length,
    });
  }

  public getBusinessCaseSummaryBuildupEntry(
    id: number,
    buildup: LumBusinessCaseSummaryBuildup,
    businessRelationRole: LumBusinessRelationRole
  ): Observable<LumBusinessCaseSummaryBuildupEntry | null> {
    return this.delayResponse(
      of({
        id: 1,
        buildupProducts: [
          {
            id: 1,
            product: {
              id: 1,
              productCategory: 'GLASS',
              platformProductId: 'bt77-bdg4q-ga8',
              alternativePlatformProductId: 'acue-ab56h-dt6',
            },
          },
          {
            id: 2,
            product: {
              id: 2,
              productCategory: 'INTERLAYER',
              customerProductId: 'CUST444',
              platformProductId: 'bt77-bdg4q-ga8',
              supplierProductId: 'SUP444',
            },
          },
          {
            id: 1,
            product: {
              id: 3,
              productCategory: 'GLASS',
              customerProductId: 'CUST2365',
              platformProductId: 'plat156750',
              supplierProductId: 'SUP2365',
              alternativePlatformProductId: 'acue-ab56h-dt6',
            },
          },
        ],
        processings: [],
      })
    );
  }

  public getBusinessCaseSummaryBuildupEntryById(
    buildupId: number
  ): Observable<LumBusinessCaseSummaryBuildupEntry | null> {
    return this.getBusinessCaseSummaryBuildupEntry(
      1,
      this.businessCaseSummaryBuildups[0],
      'SUPPLIER'
    );
  }

  public getBusinessCaseSummaryProduct(
    id: number,
    params?: LumBaseDataParams<LumBusinessCaseSummaryProduct>
  ): Observable<LumDataView<LumBusinessCaseSummaryProduct>> {
    return of({
      content: this.businessCaseSummaryProducts,
      total: this.businessCaseSummaryProducts.length,
    });
  }

  public getBusinessCaseSummaryProcessing(
    id: number,
    params?: LumBaseDataParams<LumBusinessCaseSummaryProcessing>
  ): Observable<LumDataView<LumBusinessCaseSummaryProcessing>> {
    return of({
      content: this.businessCaseSummaryProcessings,
      total: this.businessCaseSummaryProcessings.length,
    }).pipe(
      map((data) => {
        data.content = data.content.map((item) => {
          item.processingParameters = [
            { name: 'WIDTH', value: '10' },
            { name: 'HEIGHT', value: '14' },
            { name: 'DIAMETER', value: '10' },
            { name: 'DIAMETER_2', value: '12' },
            { name: 'DIAMETER_3', value: '15' },
            { name: 'RADIUS', value: '16' },
            { name: 'ANGLE', value: '19' },
            { name: 'DISTANCE', value: '20' },
            { name: 'PERCENTAGE', value: '9' },
          ];
          return item;
        });

        return data;
      })
    );
  }

  public getBusinessCaseStateHistory(
    id: number
  ): Observable<LumBusinessCaseStateHistoryEntry[]> {
    return of([
      { state: 'CUSTOMER_CONVERSION_FAILURE', timestamp: new Date() },
      { state: 'AVAILABLE_FOR_SUPPLIER', timestamp: new Date() },
      { state: 'CREATED', timestamp: new Date() },
      { state: 'FILE_DOWNLOAD_FAILURE', timestamp: new Date() },
      { state: 'FILE_UPLOAD_FAILURE', timestamp: new Date() },
      { state: 'ASSIGNED_TO_PLATFORM', timestamp: new Date() },
      { state: 'ASSIGNED_TO_SUPPLIER', timestamp: new Date() },
      { state: 'ASSIGNMENT_FAILURE_CUSTOMER', timestamp: new Date() },
      { state: 'ASSIGNMENT_FAILURE_SUPPLIER', timestamp: new Date() },
      { state: 'ROUTED', timestamp: new Date() },
      { state: 'ROUTING_FAILURE', timestamp: new Date() },
      { state: 'SUPPLIER_CONNECTOR_DISABLED', timestamp: new Date() },
      { state: 'SUPPLIER_RECEIVING_FAILURE', timestamp: new Date() },
      { state: 'CUSTOMER_ORDER_RECEIVED', timestamp: new Date() },
      { state: 'FORWARDED_TO_SUPPLIER', timestamp: new Date() },
      { state: 'CUSTOMER_ORDER_IMPORT_FAILED', timestamp: new Date() },
    ]);
  }

  public getBusinessCaseStatisticsCount(
    params: LumRequestParams
  ): Observable<LumStatisticsResponse> {
    if (Number(params['offset']) === 7) {
      return of({ count: 200 });
    }

    if (Number(params['offset']) > 7) {
      return of({ count: 100 });
    }

    return of({ count: 150 });
  }

  public getBusinessCaseStatisticsTimeSaved(
    params: LumRequestParams
  ): Observable<LumStatisticsResponse> {
    if (params['days'] === '7') {
      return of({ count: 86399999 });
    }

    return of({ count: 97499999 });
  }

  public getBusinessCaseTaskCount(): Observable<LumStatisticsResponse> {
    return of({ count: 2 });
  }
}
