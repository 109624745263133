import { Injectable } from '@angular/core';
import {
  LumAssignmentProcessing,
  LumBaseModel,
  LumProcessingCount,
} from '@lum-types';
import { LumLogger } from '@lum-utils';
import { BehaviorSubject, Observable, firstValueFrom, tap } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService } from '../api.service';
import { BaseDataAPIService } from '../base-data/base-data.api.service';
import { ProcessingService } from './processing.abstract';

@Injectable({
  providedIn: 'root',
})
export class ProcessingAPIService
  extends BaseDataAPIService<LumAssignmentProcessing>
  implements ProcessingService
{
  public processingCount$?: BehaviorSubject<LumProcessingCount | null> =
    new BehaviorSubject<LumProcessingCount | null>(null);

  constructor(
    public override apiService: ApiService,
    public override dcuplService: DcuplService
  ) {
    super(apiService, dcuplService);
    this._baseApiRoute = 'client-processing';
  }

  public getProcessingCount(): Observable<LumProcessingCount> {
    return this.apiService
      .get<LumProcessingCount>({
        endpoint: this._baseApiRoute,
        path: 'count',
      })
      .pipe(
        tap((response) => {
          this.processingCount$?.next(response);
        })
      );
  }

  public createProcessingAssignment(
    processing: LumAssignmentProcessing & LumBaseModel
  ): Observable<LumAssignmentProcessing> {
    return this.apiService.post<LumAssignmentProcessing>({
      endpoint: this._baseApiRoute,
      body: {
        ...processing,
      },
    });
  }

  public updateProcessingAssignment(
    processing: LumAssignmentProcessing & LumBaseModel
  ): Observable<LumAssignmentProcessing> {
    return this.apiService.put<LumAssignmentProcessing>({
      endpoint: this._baseApiRoute,
      path: '{id}',
      id: processing.id,
      body: {
        ...processing,
      },
    });
  }

  public importAssignments(file: File | null): Observable<void> {
    if (!file) {
      LumLogger.error('Missing file: importAssignments()');
      throw new Error('Missing file');
    }

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.apiService
      .post<void>({
        endpoint: this._baseApiRoute,
        path: 'file-upload',
        body: formData,
        responseType: 'text',
      })
      .pipe(
        tap(async () => {
          await firstValueFrom(this.getProcessingCount());
        })
      );
  }
}
