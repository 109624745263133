<div
  class="relative flex items-center gap-2 px-3 py-2 cursor-pointer hover:bg-blue-50 typo-label-sm"
>
  <input #input type="checkbox" class="form-checkbox" [checked]="isSelected" />
  <ng-content></ng-content>

  <lum-icon
    *ngIf="isSelected"
    dataCy="checkbox"
    name="check-16"
    class="absolute text-white top-2.5 left-3.5 pointer-events-none"
  ></lum-icon>
</div>
