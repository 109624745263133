import { TranslateService } from '@ngx-translate/core';
import { intervalToDuration } from 'date-fns';

export function getFormattedTimeFromMilliseconds(
  translateService: TranslateService,
  milliseconds?: number
): string {
  if (!milliseconds || milliseconds < 0) {
    return '-';
  }

  const interval = intervalToDuration({ start: 0, end: milliseconds });

  if (interval.days) {
    return translateService.instant('lum.helper.date.days-and-hours', {
      days: interval.days,
      hours: interval.hours ?? 0,
    });
  }

  return translateService.instant('lum.helper.date.hours-and-minutes', {
    hours: interval.hours ?? 0,
    minutes: interval.minutes ?? 0,
  });
}
