/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import {
  LumAlternativeProduct,
  LumBusinessRelation,
  LumBusinessRelationRequest,
  LumBusinessRelationState,
  LumDataView,
  LumStatisticsResponse,
} from '@lum-types';
import { Observable, of } from 'rxjs';
import { BaseDataMockService } from '../base-data/base-data.mock.service';
import { BusinessRelationService } from './business-relation.abstract';

@Injectable({
  providedIn: 'root',
})
export class BusinessRelationMockService
  extends BaseDataMockService<LumBusinessRelation>
  implements BusinessRelationService
{
  private readonly businessRelations: LumBusinessRelation[] = [
    {
      id: 1,
      customer: {
        id: 4,
        name: 'Company 4',
        site: 'Site 4',
        branch: 'Branch 4',
      },
      supplier: {
        id: 2,
        name: 'Company 2',
        site: 'Site 2',
        branch: 'Branch 2',
      },
      state: 'ACTIVE',
    },
    {
      id: 2,
      customer: {
        id: 3,
        name: 'Company 3',
        site: 'Site 3',
        branch: 'Branch 3',
      },
      supplier: {
        id: 4,
        name: 'Company 4',
        site: 'Site 4',
        branch: 'Branch 4',
      },
      state: 'PENDING',
    },
    {
      id: 3,
      customer: {
        id: 1,
        name: 'Company 3',
        site: 'Site 3',
        branch: 'Branch 3',
      },
      supplier: {
        id: 4,
        name: 'Company 4',
        site: 'Site 4',
        branch: 'Branch 4',
      },
      state: 'SUSPENDED',
    },
  ];

  private alternativeProducts: LumAlternativeProduct[] = [
    {
      businessRelationId: 2,
      requestedPlatformProductId: 'afrn-hiqei-fke',
      alternativePlatformProductId: 'afrn-hiqei-izg',
      productCategory: 'GLASS',
    },
    {
      businessRelationId: 2,
      requestedPlatformProductId: 'iegr-hedt4-edk',
      alternativePlatformProductId: 'iegr-hedt4-ims',
      productCategory: 'SPACER',
      clientProductId: '123',
      clientProductDetails: 'Client Product Description',
    },
  ];

  constructor() {
    super();
    this._mockData = this.businessRelations;
  }

  public createAlternativeProduct(
    alternativeProduct: LumAlternativeProduct
  ): Observable<LumAlternativeProduct> {
    return this.delayResponse(of(alternativeProduct));
  }

  public getAlternativeProducts(
    businessRelationId: number
  ): Observable<LumDataView<LumAlternativeProduct>> {
    return this.delayResponse(
      of({
        content: this.alternativeProducts,
        totalPages: 1,
        number: 1,
      })
    );
  }

  public createBusinessRelation(
    request: LumBusinessRelationRequest
  ): Observable<void> {
    return this.delayResponse(of(void 0));
  }

  public updateBusinessRelationState(
    id: number,
    state: LumBusinessRelationState
  ): Observable<void> {
    return this.delayResponse(of(void 0));
  }

  public getBusinessRelationTaskCount(): Observable<LumStatisticsResponse> {
    return of({ count: 7 });
  }
}
