<div class="items-center space-x-3 group" [ngClass]="getWrapperClasses()">
  <div
    class="items-center justify-center shrink-0"
    [ngClass]="getButtonClasses()"
  >
    <div class="flex items-center text-left" [ngClass]="getContentClasses()">
      <div
        class="shrink-0"
        *ngIf="isIconContainerVisible()"
        [ngClass]="getIconClasses()"
      >
        <ng-container *ngIf="!loading">
          <lum-icon *ngIf="icon" [size]="iconSize" [name]="icon"></lum-icon>
        </ng-container>
        <lum-spinner *ngIf="loading" [size]="iconSize"></lum-spinner>
      </div>
      <div class="font-semibold typo-label-sm">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
