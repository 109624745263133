<button
  [disabled]="isDisabled"
  class="flex items-center"
  (click)="onChange()"
  [attr.aria-checked]="isChecked"
  type="button"
  role="switch"
  [attr.data-cy]="
    'toggle-' + dataCy + '-' + (isChecked ? 'active' : 'inactive')
  "
>
  <div
    class="relative inline-flex flex-shrink-0 h-5 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full w-[34px] focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
    [ngClass]="{
      'bg-blue-500': isChecked && !isDisabled,
      'bg-blue-700': !isChecked && !isDisabled,
      'bg-gray-300 cursor-not-allowed': isDisabled,
    }"
  >
    <span
      aria-hidden="true"
      class="inline-block w-4 h-4 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full pointer-events-none shadow-toggle ring-0"
      [ngClass]="{
        'translate-x-3.5': isChecked,
        'translate-x-0': !isChecked,
      }"
    >
    </span>
  </div>
  <div class="ml-2 typo-label-sm">
    <h6
      class="font-semibold text-left"
      *ngIf="innerLabel"
      [ngClass]="getLabelClasses()"
      [innerHTML]="innerLabel | translate"
    ></h6>
    <p *ngIf="additionalInfo" class="text-left">
      {{ additionalInfo | translate }}
    </p>
  </div>
</button>
