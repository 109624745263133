import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { LumConnector, LumConnectorCredentials, LumDataSource } from '@lum-types';
import { StorageUtils } from '@lum-utils';
import { Observable } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService } from '../api.service';
import { BaseDataService } from '../base-data/base-data.abstract';
import { ConnectorAPIService } from './connector.api.service';
import { ConnectorMockService } from './connector.mock.service';

export abstract class ConnectorService extends BaseDataService<LumConnector> {
  public abstract generateConnectorCredentials(connectorId: number): Observable<LumConnectorCredentials>;
  public abstract disableConnector(connectorId: number, disabled: boolean): Observable<void>;
}

export function ConnectorFactory(apiService: ApiService, dcuplService: DcuplService): ConnectorService {
  if (StorageUtils.get<LumDataSource>('lum.data-source') === 'mock-data') {
    return new ConnectorMockService();
  }

  return new ConnectorAPIService(apiService, dcuplService);
}

export const LUM_CONNECTOR_PROVIDER: FactoryProvider = {
  provide: ConnectorService,
  useFactory: ConnectorFactory,
  deps: [ApiService, DcuplService, HttpClient],
};
