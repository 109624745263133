import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LumDateTimeFormat } from '../types/date.type';

@Pipe({
  name: 'localeDate',
})
export class LumLocaleDatePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) {}

  public transform(
    value: Date | string | number,
    format?: LumDateTimeFormat[],
    locale?: string,
    delimiter?: string
  ): string {
    if (!value) {
      return '-';
    }

    const currentLocale = locale ?? this.translateService.currentLang ?? 'en';

    if (!format?.length) {
      return (
        this.datePipe.transform(value, undefined, undefined, currentLocale) ??
        '-'
      );
    }

    return format
      .map((f) => this.datePipe.transform(value, f, undefined, currentLocale))
      .join(delimiter ?? ' ');
  }
}
