/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { getLabelByAssignmentCategory } from '@lum-helpers';
import {
  LUM_PRODUCT_CATEGORY,
  LumAssignmentCategory,
  LumClientProduct,
  LumFilter,
  LumProductCategory,
  LumProductCategoryMetaData,
  LumProductOverview,
} from '@lum-types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseDataMockService } from '../base-data/base-data.mock.service';
import { ProductService } from './product.abstract';

@Injectable({
  providedIn: 'root',
})
export class ProductMockService
  extends BaseDataMockService<LumClientProduct>
  implements ProductService
{
  public productOverview$?: BehaviorSubject<LumProductOverview | null> =
    new BehaviorSubject<LumProductOverview | null>(null);

  public selectedProductCategory$: BehaviorSubject<LumProductCategory | null> =
    new BehaviorSubject<LumProductCategory | null>('GLASS');

  private readonly products: LumClientProduct[] = [
    {
      id: 1,
      productCategory: 'GLASS',
      clientProductId: '123 glass',
      connectorId: 1,
    },
    {
      id: 2,
      productCategory: 'GLASS',
      clientProductId: '456 glass',
      platformProductId: {
        key: 'dcupl 123',
      },
      connectorId: 1,
    },
    {
      id: 3,
      productCategory: 'GLASS',
      clientProductId: 'glass 123',
      connectorId: 1,
    },
    {
      id: 4,
      productCategory: 'GLASS',
      clientProductId: 'z glass 456',
      connectorId: 1,
    },
    {
      id: 5,
      productCategory: 'GEORGIAN_BAR',
      clientProductId: '123 georgian bar',
      platformProductId: { key: '6468797', _dcupl_ref_: 'miss' },
      connectorId: 1,
    },
    {
      id: 6,
      productCategory: 'GEORGIAN_BAR',
      clientProductId: '456 georgian bar',
      connectorId: 1,
    },
  ];

  constructor() {
    super();
    this._mockData = this.products;
  }

  public getProductOverview(): Observable<LumProductOverview> {
    const items: LumProductCategoryMetaData[] = LUM_PRODUCT_CATEGORY.map(
      (productCategory) => {
        const filteredGroup = this._mockData.filter(
          (e) => e.productCategory === productCategory
        );
        return {
          productCategory,
          assignedCount: filteredGroup.filter(
            (e) => e.platformProductId !== undefined
          ).length,
          totalCount: filteredGroup.length,
          label: getLabelByAssignmentCategory(productCategory),
        };
      }
    );
    const overview = { connectorId: 5, items };
    this.productOverview$?.next(overview);
    return of(overview);
  }

  public updateProductAssignment(
    clientProduct: LumClientProduct
  ): Observable<LumClientProduct> {
    return of(clientProduct);
  }

  protected override applyFilter(
    items: LumClientProduct[],
    f: LumFilter<LumClientProduct>
  ): LumClientProduct[] {
    if (f.propertyName === 'platformProductId') {
      items = items.filter((item) => {
        if (f.propertyName in item) {
          if (f.value === true) {
            return item[f.propertyName as keyof LumClientProduct] !== undefined;
          }
          if (f.value === false) {
            return item[f.propertyName as keyof LumClientProduct] === undefined;
          }
        }
        return item;
      });
    } else {
      items = items.filter((item) => {
        if (f.propertyName in item) {
          return item[f.propertyName as keyof LumClientProduct]
            ?.toString()
            .toLowerCase()
            .includes(f.value?.toString()?.toLowerCase() ?? '');
        }
        return false;
      });
    }

    return super.applyFilter(items, f);
  }

  public importAssignments(
    file: File | null,
    assignmentCategory?: LumAssignmentCategory
  ): Observable<void> {
    return of(void 0);
  }
}
