import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LumFormFieldExtComponent } from '@lum-form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lum-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent
  extends LumFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() isReadonly = false;
  @Input({ required: true }) innerLabel = '';

  public isChecked = false;
  public isDisabled = false;
  public hasError = false;
  private subscriptions = new Subscription();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.listenForFormControlChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForFormControlChanges(): void {
    this.isChecked = this.control?.value;
    this.isDisabled = this.control?.disabled ?? false;
    this.subscriptions.add(
      this.control?.valueChanges.subscribe((value) => {
        this.isChecked = value;
        this.hasError = !!this.control?.errors;
      })
    );
  }

  public onChange(): void {
    this.control?.patchValue(!this.isChecked);
    this.control?.markAsDirty();
    this.control?.markAsTouched();
  }

  public getClasses(): string[] {
    const classes = [];

    if (this.isDisabled) {
      classes.push('text-gray-400');
    }

    return classes;
  }
}
