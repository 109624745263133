import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@lum-ui/icon';
import { RadioComponent } from './radio.component';

@NgModule({
  declarations: [RadioComponent],
  imports: [CommonModule, IconModule, IconModule],
  exports: [RadioComponent],
})
export class RadioModule {}
